@import '../../../styles/styles';

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 1;

  &__title {
    margin-bottom: 2.2rem;
    font-size: 21px;
    color: #060c14;
    text-align: center;
    font-weight: bold;
  }

  &__image {
    width: 8rem;
    height: 8rem;
  }

  &__name {
    color: $color-primary;
    font-size: 22px;
    margin: 1rem;
    font-weight: bold;
  }

  @include from($x-large) {
    position: relative;

    &__title {
      display: none;
    }

    &__image {
      width: 127px;
      height: 127px;
    }

    &__name {
      font-size: $xl-title-font;
    }
  }

  @include from($high-quality) {
    &__name {
      font-size: $hq-title-font;
    }
  }
}

@media (min-width: 2080px) {
  .profile-card {
    &__image {
      width: 13rem;
      height: 13rem;
    }
  }
}
