.notificationsPanel{
    
    .closePanel{
        position: absolute;
        right: 20px;
        width: 22px;
        height: 22px;
        top: 30px;
        cursor: pointer;
        path{
            stroke: #fff;
        }
    }
    .notificationsContent{
        position: absolute;
        background-color: #1F0D3F;
        width: 100%;
        right: 0px;
        border: 1px solid #6429CD;
        border-radius: 0 0px 25px 25px;
        text-align: center;
        padding: 50px 30px 30px 30px;
        top: 81%;
        z-index: -1;
        &:before{
            content: "";
            position: fixed;
            z-index: -2;
            backdrop-filter: blur(1px);
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
        }
        .notificationEmpty{
            .bellFill{
                width: 65px;
                height: 65px;
                path{
                    fill: #fff;
                }
            }
            p{
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                padding: 10px 50px;
            }
        }
        
    }
    .notification{
        text-align: left;
        margin-top: 30px;
        padding-bottom: 10px;
        h3, p{
            font-size: 16px;
            color: #fff;
        }
        h3{
            font-weight: 700;
            line-height: 16px;
        }
        p{
            margin: 30px 0px;
            line-height: 22.4px;
        }
        a{
           button{
            background-color: #6429CD; 
            padding: 5px 30px;
            color: #fff;
            border-radius:  1em;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            border:none;
            -webkit-transition: all 500ms ease-in-out;
            -moz-transition: all 500ms ease-in-out;
            -ms-transition: all 500ms ease-in-out;
            -o-transition: all 500ms ease-in-out;
            transition: all 500ms ease-in-out;
            &:hover{
                box-shadow: 0px 0px 20px 0px rgba(189, 189, 255, 0.2) inset;
                box-shadow: 0px 0px 15px 0px rgba(100, 41, 205, 1);
            }
           }
        }
    }
}