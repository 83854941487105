@import 'styles/styles.scss';

.iconContainer {
  align-items: center;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
}

.accordion {
  display: flex;
  flex-direction: column;
  color: #828285;
  width: 100%;
  cursor: pointer;

  &.disabled {
    cursor: default;
    color: #d2d2d6;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 1.2rem;
    background: #330072;
    border: 1px solid #330072;
    border-radius: 100px;
    opacity: 1;
    cursor: pointer;
    min-width: 100px;
    width: 100%;

    &.disabled {
      cursor: default;
      pointer-events: none;
      color: #d2d2d6;
      background: rgba(100, 41, 205, 0.08);
      border: 1px solid #330072;
      border-radius: 100px;
    }

    &__icon {
      display: flex;
      width: 3rem;
      height: 2rem;
      margin-left: 0.8rem;

      @include tablet {
        width: 4rem;
        height: 2rem;
        margin-left: 0.2rem;
      }

      &.for-password {
        min-width: 14px;
      }
    }

    &.input-error {
      background: rgba(237, 0, 57, 0.08);
      border: 1px solid #660019;
      box-shadow: 0px 0px 10px #ed0039;
    }

    &.for-password {
      border: 0;
      background: #330072;
      border-radius: 25px;

      &--modifier {
        height: 5.5rem;
      }
    }

    &.non-for-password {
      border: 0;
      background: #330072;

      &--modifier {
        height: 5.5rem;
      }
    }

    .non-for-icon {
      transform: rotate(180deg);
      transition: 0.4s ease;
    }

    &__text {
      width: 100%;
      margin: 0 1rem;
      font-size: 17px;
      color: $color-white;
      font-weight: bold;

      &.empty {
        font-style: italic;
        font-weight: 300;
      }
    }

    &__arrow {
      width: 14px;
      height: 14px;

      &.for-password {
        color: #fff;
        border-radius: 50%;

        &.help {
          margin: 0;
        }
      }
    }
  }
}

.phoneCode .accordion__input__text {
  position: relative;
  margin-left: 5px;

  &::before {
    content: '+';
  }
}

.country .accordion__input__text,
.department .accordion__input__text {
  margin-left: 0;
}

ul li ul {
  margin: 0;
  padding: 1rem 0 !important;
}

h1 {
  color: #330072;
  padding-top: 3rem;
  padding-left: 1rem;
  font-weight: bold !important;
  font-size: 21px;
  line-height: 29.4px;
}