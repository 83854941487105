@import '../../../styles/styles.scss';

.tab {
  position: relative;
  border: none;
  background: none;
  color:white;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.4rem;
  cursor: pointer;
  transition: all 0.4s ease-in;
  border-bottom: .1rem solid $color-primary;
  
  &__text{
    font-size: 1.5rem;
    padding: 1.3rem 0;

    &--active{
      font-weight: 600;
    }

    &--modifier{
      color: #330072;
    }

    &--lock{
      color: #9D9DA1;
    }
  }

  &::after {
    content: '';
    margin: 0.35rem auto;
    width: min(100%, 40rem);
    display: block;
    height: 0.2rem;
    border-radius: 0.2rem;
    transition: background-color 0.3s ease-in;
  }

  &--active {
    pointer-events: none;

    &-line {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
      box-shadow: 0 -.3rem .3rem rgba(189, 189, 255, 0.3);
      border-radius: 12rem;
      height: .5rem;

      &--modifier{
        background-color: #330072;
      }

      &--lock{
        background-color: #9D9DA1;
      }
    }


  }

  &--lock {
    cursor: default;
  }

  @include from($x-large) {
    font-size: 1.6rem;
    margin-bottom: 0;

    &::after {
      height: 0.1rem;
      margin-bottom: 0;
    }
  }

  @include from($high-quality) {
    font-size: 2rem;
  }
}
