@import '../../../styles/styles.scss';

.bgGrey {
  background-color: $color-grey;
  min-height: 82vh;
  padding-inline: 8%;
  padding-top: 4rem;
  padding-bottom: 3%;
}
body {
  overflow: auto !important;
}

//input search bar
.searchBar {
  display: flex;
  width: 100%;
  color: $color-primary;
  font-size: large;
  flex-direction: column;
  margin-bottom: 2rem;
  h1 {
    font-weight: bolder;
    margin-bottom: 1.5rem;
  }
  &__input {
    position: relative;
    display: flex;
    align-items: center;
    .searchIcon {
      position: absolute;
      background-color: $color-primary;
      display: flex;
      right: 5px;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0px 0px 10px 0px hsl(162deg, 100%, 46%) inset;
  }

  input {
    height: 40px;
    width: 100%;
    background: rgba(100, 41, 205, 0.08);
    border: 1px solid #330072;
    border-radius: 100px;
    color: #330072;
    font-size: $mobile-font;
    padding: 0 1.2rem;

    &:focus {
      border: 1px solid #330072;
      box-shadow: 0px 0px 15px $color-primary;
      outline: none;
    }

    &.disabled {
      background: rgba(100, 41, 205, 0.08);
      border: 1px solid #330072;
      border-radius: 100px;
      color: #330072;
      opacity: 0.5;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  @include from($x-large) {
    input {
      height: 40px;
      font-size: $xl-font;
    }
  }

  @include from($high-quality) {
    input {
      height: 40px;
      font-size: $hq-font;
    }
  }
}
.banner {
  min-height: 100px;
  width: 100%;
  background-color: $color-grey;
  background-image: url('../../../utils/assets/images/bannerHeader.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 1px;
  padding-inline: 8%;
  h1 {
    padding-bottom: 30px;
    padding-right: 50px;
  }
  img {
    width: auto;
    height: 65%;
  }
}
.bannerText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

// .container {
//   width: 300px;
//   height: 5px;
//   background: #ffffff;
//   border-radius: 5px;
//   box-shadow: 0px -4px 20px #bdbdff;
// }

.IconLogin {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #330072;

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 28% 0 0 28%;

    color: #ffffff;
  }
}
.CenterText {
  margin: 3% 0 0 -2%;
}

.menu {
  position: absolute;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  width: 150px;
  top: 95%;
  right: -130%;
}

.menu > li {
  margin: 0;
  background-color: $color-grey;
}

.menu > li:hover {
  background-color: lightgray;
}

.activeImage {
  visibility: hidden;
}

.menu > li > button {
  width: 100%;
  height: 35px;
  align-items: center;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  margin-top: 5px;
  font: inherit;
  font-size: 12px;
  cursor: pointer;
  background: #330072;
  /* Primary 60%/Purple/Primary-purple-500 */
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #330072;
  /* inner + shadow/03 */
  box-shadow: 0px 12px 24px rgba(100, 41, 205, 0.6),
    inset 0px 0px 20px rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  display: flex;
  /* Caption/Regular */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  /* Shades/White-50 */
  color: #ffffff;
  text-align: center;
  img {
    height: 15px;
    width: 15px;
    margin-right: 8%;
    margin-left: 2%;
  }
}

.menu > li > button:hover {
  background: #6429cd; /* Cambia el color de fondo al hacer clic */
  border-color: #6429cd; /* Cambia el color del borde al hacer clic */
  box-shadow: 0px 12px 24px rgba(100, 41, 205, 0.6),
    inset 0px 0px 20px rgba(255, 255, 255, 0.6);
}

.footer-admin-container {
  padding-inline: 8%;
  padding-bottom: 2rem;
  background-color: $color-grey;
}