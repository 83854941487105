.wrapper-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;

  &__cardimg {
    width: 6rem;
    height: 10rem;
    margin-top: 4rem;
    padding: auto;
  }

  &__title {
    font-size: 2.1rem;
    font-weight: 400;
    margin: 1rem;
  }

  &__comment {
    font-size: 1.8rem;
    margin: 1rem;
    padding: 0.5rem;
    font-weight: 600;
    text-align: center;
  }
}