.triangle {
  position: absolute;
  width: 2rem;
  height: 2rem;

  &--top {
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 2rem solid #DFDFFF;
  }

  &--left {
    top: 50%;
    left: -2rem;
    transform: translateY(-50%);
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 2rem solid #DFDFFF;
  }

  &--right {
    top: 50%;
    right: -2rem;
    transform: translateY(-50%);
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 2rem solid #DFDFFF;
  }
}