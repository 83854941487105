@import '../../../styles/styles.scss';
.TabPanel {
  padding-top: 3rem;
  z-index: 1;

  &__tab-list {
    display: flex;
    gap: 4px;
    padding: 10px 5px 0 2px;
    margin: 0;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & > .form-tab {
      flex: 1;
    }
  }

  &__section {
    padding: 4rem 0rem 2rem 0rem;
    display: flex;
    flex-direction: column;
  }

  &__section-buttons {
    display: flex;
    justify-content: space-between;
    margin: 12px;

    & button {
      width: 100%;
    }
  }
}

.tab__border-bottom {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -2px;
  z-index: 11;
  background-color: #bdbdff;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1.5rem 0px;

  &__title {
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: hsla(267, 100%, 22%, 1);
  }
  &__question {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: hsla(267, 100%, 22%, 1);
  }
  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: hsla(267, 100%, 22%, 1);
  }

  &__content {
    --n: 4;
    /* The maximum number of columns */
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(200px, (100% - (var(--n) - 1) * 10px) / var(--n)), 1fr)
    );

    gap: 10px;
    margin: 5px;
    align-items: start;
    margin-top: 1rem;
    gap: 39px;

    &--full-width {
      display: block;
    }
  }
}

.multiple__button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: hsla(262, 67%, 48%, 1);
}

.Multiple {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
