

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: $font-poppins;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: #c1c1c1;
}

::-webkit-scrollbar:vertical {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 2rem;
}

html {
  font-size: 62.5%;

  @include mobile {
    scrollbar-width: none;
  }

  @include tablet {
    scrollbar-width: thin;
  }
}

body {
  box-sizing: border-box;

  &:not(.sb-show-main) {
    overflow: hidden;
  }
}

.app {
  min-height: 100vh;
}

li,
a {
  list-style: none;
  text-decoration: none;
  color: #330072;
}

button,
input {
  font-family: $font-poppins, inherit;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
