@import './../../../styles/styles.scss';
.modalFont {
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  position: fixed;
  backdrop-filter: blur(20px);
  left: 0px;
  top: 0px;
}

.modal-large {
  width: max-content;
  height: max-content;
  align-items: center;
  backdrop-filter: blur(40px);
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Cataloge {
  height: 130%;
  width: 200%;
  justify-content: space-between;
  border-radius: 15%;
}
.catalogeText {
  font-family: 'Poppins';
  font-size: 30px;
  margin-top: 0px;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  margin-top: -55px;
}
.catalogeLoading {
  max-height: 250px;
  max-width: 350px;
  text-align: center;
  font-size: 50%;
}
