@import 'styles/variables';
@import 'styles/mixins';

.input {
  display: flex;
  width: 100%;
  flex-direction: column;

  input {
    height: 40px;
    width: 100%;
    background: rgba(100, 41, 205, 0.08);
    border: 1px solid #330072;
    border-radius: 100px;
    color: #330072;
    font-size: $mobile-font;
    padding: 0 1.2rem;

    &:focus {
      border: 1px solid #330072;
      box-shadow: 0px 0px 15px $color-primary;
      outline: none;
      color: #330072;
      background: rgba(100, 41, 205, 0.08);
    }

    &:disabled {
      background: rgba(100, 41, 205, 0.08);
      border: 1px solid #330072;
      border-radius: 100px;
      color: #330072;
      opacity: 0.5;
    }

    &::placeholder {
      color: #6429CD66;
    }
    &:hover {
      background-color: #6429cd;
      box-shadow: inset 0px 0px 20px rgba(189, 189, 255, 0.6);
      filter: drop-shadow(0px 12px 24px rgba(100, 41, 205, 0.6));
      border: none;
    }

  }

  &--error {
    input {
      background: rgba(237, 0, 57, 0.08);
      border: 1px solid #660019;
      box-shadow: inset 0px 0px 10px #ed0039;
      color: hsla(345, 100%, 20%, 1);

      &::placeholder {
        color: #660019;
      }
    }

    .input__subtext {
    }
  }

  &--success {
    input {
      border: 1px solid hsla(162, 100%, 21%, 1);
      background: hsla(162, 100%, 36%, 0.08);
      box-shadow: 0px 0px 10px 0px hsla(162, 100%, 46%, 1) inset;
      color: hsla(162, 100%, 21%, 1);
    }

    .input__subtext {
    }
  }

  &__icon {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    color: #330072;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0px 0px 10px 0px hsl(162deg, 100%, 46%) inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  @include from($x-large) {
    input {
      height: 40px;
      font-size: $xl-font;
    }
  }

  @include from($high-quality) {
    input {
      height: 40px;
      font-size: $hq-font;
    }
  }
}

.subtext {
  /* margin-top: 1rem; */
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 8px;

  &__image {
    width: 20px;
    height: 20px;
  }

  &--success {
    color: hsla(162, 100%, 20%, 1);

    & path {
      fill: hsla(162, 100%, 21%, 1);
    }
  }

  &--error {
    color: hsla(345, 100%, 20%, 1);

    & path {
      fill: hsla(345, 100%, 20%, 1);
    }
  }
}
