@import '../../../styles/styles.scss';
$width: 22.5rem;

.activityAtomLink {
  border: none;
  background-color: #9b9da1;
  color: #FFF;
  border-radius: 1rem;
  width: $width;
  height: $button-atom-height;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  transition: background-color .3s ease;
  font-size: 1.6rem;

  &:hover {
    background-color: #767b83;
  }



  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}