@import 'styles/styles';

.scheduleMobile{
  display: grid;
  grid-template-rows: 7vh 20vh 70vh;
  padding: 0 calc(17px + 0.6rem);
}

.schedule {
  position: relative;

  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include desktop{
      justify-content: unset;
      gap: 2.5rem;
      margin-bottom: 2.6rem;
    }
  }

  &__button{
    position: absolute;
    left: 0;

    @include desktop{
      position: unset;
      left: unset;
      margin-left: unset;
    }
  }
  
  &__title {
    font-size: 2.1rem;
    font-weight: bold;
    color: $color-black;
    
    @include desktop {
      text-align: left;
      font-size: 3rem;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 500;
    color: $color-grey-black;
    margin-bottom: 1.6rem;
    
    @include desktop {
      font-size: 2.1rem;
      font-weight: bold;
      color: $color-black;
    }
  }

  &__date {
    margin-top: 12px;
    margin-bottom: calc(31px - 20px); //31px - (20px from the bottom padding of the days container)
    
    @include desktop {
      margin-top: 13px;
    }

    &__month {
      font-size: 1.6rem;
      color: $color-super-black;
      
      @include desktop {
        font-size: 2.6rem;
      }
    }
    
    &__year {
      font-size: 1.6rem;
      font-weight: bold;
      color: $color-primary;
      margin-left: 4px;
      
      @include desktop {
        font-size: 2.6rem;
        margin-left: 6px;
      }
    }

    &__days {
      padding: 5px 0 20px;
      margin-top: calc(11px - 5px); // 11px - (5px from top padding)
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      column-gap: 14px;
      padding-right: $padding-x;
      padding-left: $padding-x;
      
      /** Ocultar scrollbar horizontal: Firefox */
      scrollbar-width: none;
      
      /** Ocultar scrollbar horizontal: Chrome */
      &::-webkit-scrollbar {
        display: none;
      }

      @include desktop {
        margin-top: calc(24px - 5px); // 24px - (5px from top padding)
      }
    }
  }

  &__boxHours{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include desktop {
      display: unset;
      flex-direction: unset;
      justify-content: unset;
    }
  }

  &__partOfDaysContainer {
    height: 100%;
    
    @include desktop {
      margin-top: 31px;
    }
  }

  &__btnSubmit {
    display: flex;
    justify-content: center;
    margin-bottom: 6.8rem;
    
    @include desktop {
      width: min(90%, 254px);
      margin-top: unset;
      justify-content: flex-start;
    }
  }
}
