@import '../../../styles/variables';
@import '../../../styles/styles.scss';

.progress-bar {
    position: relative;
    width: 100%;
    height: 1.5rem;
    border: .25rem solid rgba(100, 41, 205, 0.6);
    border-radius: 5rem;

     &__box{
      height: 100%;
      border-right: .3rem solid rgba(100, 41, 205, 0.6);
      opacity: 0;

      &--show{
        animation: opacity .5s;
        animation-fill-mode: forwards;
      }

      &--container{
        z-index: 5;
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 5rem;
      }

    }

  &__background {
    position: absolute;
    border-radius: 12px 0 0 12px;
    background: linear-gradient(270deg, rgba(151, 151, 234, 0.7) 0.86%, rgba(215, 215, 255, 0.7) 99.14%);
    height: 100%;
    transition: all 1.5s;
    max-width: 100%;
    width: 0%;
    opacity: 0;
    animation: opacityAndWidth 3s;
    animation-fill-mode: forwards;

    &-completed{
      border-radius: 12px;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes opacity {
  100%{
    opacity: 100;
  }
}

@keyframes opacityAndWidth {
  50%{
    opacity: 100;
  }
  100%{
    width: 100%;
    opacity: 100;
  }
}