@use 'styles/styles.scss' as *;

.countdown {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 21px;
  font-weight: 400;
  color: $color-purple;
}
