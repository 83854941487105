@import '../../../styles/styles.scss';

.stagesActives {
  margin-bottom: 1.3rem;
  &--active {
    z-index: 1000;
    position: relative;
  }
  &__title {
    font-weight: 600;
    font-size: 1.8rem;
    color: #FFFFFF;
    text-shadow: 0 0 1rem rgba(255, 255, 255, 0.25);
    margin-bottom: 2.2rem;
    &--lock{
      color: #9D9DA1;
    }
    &--noLock{
      opacity: 0.4;
    }
  }

  @include laptop {
    &__title {
      font-size: 1.8rem;
    }
  }

  @include from($high-quality) {
    &__title {
      font-size: 2.4rem;
      margin-bottom: 2.34rem;
    }
  }
}
