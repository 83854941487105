.AcademicInfo {
  display: grid;
  gap: 2rem;

  &__item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);

    &__content {
      display: grid;
      grid-template-columns: subgrid;
      grid-template-rows: subgrid;
      grid-column: 1 / span 3;
      grid-row: 2 / span 3;
      gap: 3rem;
    }
  }

  &__delete {
    width: 100%;
    grid-column: 1 / span 3;
    grid-row: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      border: 1px solid hsla(262, 67%, 48%, 1);
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      path {
        fill: hsla(262, 67%, 48%, 1);
      }
    }
  }

  &__AddButton {
    align-self: center;
  }
}
