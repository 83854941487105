@import '../../../styles/styles.scss';

.customSelectContainer {
  position: relative;
  font-size: small;
  align-self: flex-end;
}

.selectedValue {
  display: flex;
  padding: 8px;
  width: auto;
  color: $color-primary;
  cursor: pointer;
  gap: 8px;
  transition: all 0.3s ease;
}

.optionsContainer {
  position: absolute;
  color: white;
  border-radius: 15px;
  overflow: hidden;
  background: rgba(100, 41, 205, 0.08);
  border: 1px solid $color-purple;
  box-shadow: 0px 0px 15px $color-primary;
  width: auto;
  background: #1f0d3f;
  z-index: 100;
}

.optionItem {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: radial-gradient(ellipse, #451895 0%, #9066d4 100%);
  }
}
.rotated {
  transform: rotate(180deg);
}
