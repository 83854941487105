@import '../../../styles/styles.scss';

.change-pass {
    
    &__main {
        display: grid;
        grid-template-rows: 5.8rem min-content;
        width: 90%;
        border-radius: 2.5rem;
        background-color: #0A090C;
        border: 1px solid $color-primary;
        box-shadow: inset 0px 0px 30px rgba(100, 41, 205, 0.5);
        filter: drop-shadow(0px 13px 60px rgba(100, 41, 205, 0.8));
        
        @include tablet{
            width: 59rem
        }
    }
    
    &__wrapper {
        display: grid;
        width: 100%;
        padding: 3rem 2.3rem 2.9rem 2.3rem;
        row-gap: 2.4rem;
        
        @include tablet {
            padding: 3rem 7.3rem 2.9rem 7.3rem;
        }
        
        &-tittle {
            width: 100%;
            background-color: $color-primary;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2.5rem 2.5rem 0 0;
            color: $color-white;
            font-size: 1.5rem;
            
            @media (max-width: 320px) {
                font-size: 1.3rem;
            }
        }
        
        &-icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-description{
            width: 100%;
        }

        &-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &__tittle{
        font-weight: 600;
    }
    
    &__icon {
        width: 8.3rem;
    }

    &__description {
        color: $color-white;
        font-size: 1.6rem;
        text-align: center;
        line-height: 1.8rem;
    }

    &__form {
        width: 100%;
        display: grid;
        row-gap: 2.8rem;

        @include tablet {
            padding: 0 7.3rem;
        }

        &-box {
            width: 100%;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            border: .1rem solid $color-white;
            color: #e8e8ed;
            border-radius: 100px;
            width: 100%;

            &:hover{
                box-shadow: 0 0 7px white;
            }

            &--error {
                border: .1rem solid #ED0039;
                box-shadow: inset 0 0 10px #ED0039;

                &:hover {
                    box-shadow: inset 0 0 10px #ED0039 !important;
                }
            }
        }

        &-input {
            color: #828285;
            padding: 2rem 1rem;
            height: 4rem;
            width: 100%;
            border: none;
            background: transparent;
            font-size: 1.4rem;

            &::placeholder{
                color: #828285;
            }

            &:hover{
                &::placeholder{
                    color: $color-white;
                }
            }

            &:focus {
                outline: none;
            }
        }

        &-btnPass {
            border: none;
            margin-right: .5rem;
            background-color: #ffffff;
            border-radius: 50%;
            height: 3.2rem;
            width: 3.5rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            &--error{
                border: none;
                margin-right: .5rem;
                background-color: #ED0039;
                border-radius: 50%;
                height: 3.2rem;
                width: 3.5rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__button {
        height: 4rem;
    }

    &__error-paragraph{
        display: flex;
        align-items: center;
        width: 100%;
        color: #FFB8C9;
        font-size: 1.2rem;
        text-align: left;
        margin-top: .9rem;
    }

    &__error-icon{
        margin-right: .7rem;
    }
}   