@import '../../../styles/media-queries';
@import '../../../styles/variables';

.error-boundary {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__tittle {
    color: $color-white;
    font-weight: 700;
    font-size: 5rem;
    font-style: normal;
    text-align: center;
    line-height: 140%;

    @include laptop {
      font-size: 7.2rem;
    }
  }

  &__text {
    color: $color-white;
    text-align: center;
    font-weight: 400;
    font-size: 2.4rem;
    width: 100%;

    @include laptop {
      font-size: 2.8rem;
    }
  }
}