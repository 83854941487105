$background-color: #F9F9FD;
$background-panel-color: #f6f7fc;
$success-color: hsla(162, 100%, 36%, 1);
$primary-color: hsla(262, 67%, 48%, 1);
$error-color: hsla(346, 100%, 46%, 1);
$tab-panel-border-radius: 25px;
$border-color: hsla(267, 100%, 22%, 1);

.VerticalTabPanel {
  background-color: $background-color;
  border: 1px solid $background-color;
  box-shadow: 0px 0px 60px 0px hsla(0, 0%, 100%, 1) inset;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.06))
    drop-shadow(0 3px 6px rgba(0, 0, 0, 0.06));
  border-radius: $tab-panel-border-radius;
  display: flex;

  &__TabList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100%;
    padding: 0;
    margin: 0;
    max-width: 371px;
    min-width: 260px;
    width: 100%;
  }

  &__Tab {
    background-color: $background-color;
    border: none;
    color: $primary-color;
    cursor: pointer;
    margin: 0;
    font-size: 21px;
    min-height: 156px;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 32px;
    flex-grow: 1;
    font-weight: 600;

    &:first-child {
      border-top-left-radius: $tab-panel-border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $tab-panel-border-radius;
      border-bottom: none;
    }

    &--previous-tab {
      border-bottom: none;
    }

    &--selected {
      background-color: $primary-color;
      color: $background-color;
      border: none;
      z-index: 1;
      --box-shadow-color: hsla(262, 67%, 48%, 1);
      --box-shadow-inset-color: hsla(240, 100%, 87%, 0.2);
      box-shadow: 0px 0px 20px 0px var(--box-shadow-inset-color) inset,
        0px 0px 15px 0px var(--box-shadow-color);

      &.VerticalTabPanel__Tab--success {
        background-color: $success-color;
        border-right: 1px solid $success-color;
        --box-shadow-inset-color: hsla(162, 100%, 86%, 0.4);
        --box-shadow-color: hsla(162, 100%, 46%, 1);

        & .VerticalTabPanel__TabIcon--success {
          background-color: #fff;

          & path {
            fill: $success-color;
          }
        }
      }

      &.VerticalTabPanel__Tab--error {
        background-color: $error-color;
        border-right: 1px solid $error-color;
        --box-shadow-color: hsla(346, 100%, 46%, 1);
        --box-shadow-inset-color: hsla(346, 100%, 86%, 0.4);

        & .VerticalTabPanel__TabIcon--error {
          background-color: #fff;

          & path {
            fill: $error-color;
          }
        }
      }
    }
  }

  &__TabContent {
    min-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    max-width: 237px;
  }

  &__TabIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 38px;

    &--error {
      border-radius: 100px;
      width: 38px;
      height: 38px;
      filter: drop-shadow(0px 4px 4px #eb00371f)
        drop-shadow(0px 6px 12px #eb003766);
      background: #ed0039;
    }

    &--success {
      border-radius: 100px;
      width: 38px;
      height: 38px;
      background-color: $success-color;
      box-shadow: 0px 12px 24px 0px hsla(162, 100%, 46%, 0.4);

      & path {
        fill: #fff;
      }
    }
  }

  &__Panel {
    background-color: $background-panel-color;
    width: 100%;
    border-radius: 0 $tab-panel-border-radius $tab-panel-border-radius 0;
    overflow-y: auto;
  }
}
