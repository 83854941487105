.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  justify-content: center;
  align-items: center;
  z-index: 12;
  &.open {
    display: flex;
  }
  .modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ModalContent {
    min-width: 839px;
    max-width: 839px;
    max-height: 500px;
    position: relative;
    .ModalHeader {
      background-color: #fff;
      padding: 23px 37px 23px 37px;
      border-radius: 25px 25px 0px 0px;
      .ModalTitle {
        font-size: 26px;
        color: #330072;
        font-weight: 700;
      }
      .closeButton {
        position: absolute;
        right: 22px;
        top: 22px;
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
    .ModalChildren {
      overflow: auto;
      max-height: 455px;
      background-color: #f6f7fc;
      padding: 23px 37px 45px 37px;
      border-radius: 0px 0px 25px 25px;
    }
  }
}
