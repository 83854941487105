@import '../../../styles/styles.scss';

.not-stages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  font-size: 1.4rem;
  text-align: center;
  align-self: center;

  &__link {
    cursor: pointer;
    color: $color-tertiary;
  }

  &>img:nth-child(1) {
    width: 7.4rem;
    height: 7.4rem;
    margin-bottom: 1.1rem;
  }

  &>p:nth-child(2) {
    color: white;
    margin-bottom: .8rem;
  }

  &>p:nth-child(3) {
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: .8rem;
    line-height: 140%;
  }

  &>p:nth-child(4) {
    color: white;
    font-weight: 300; // lighter
    font-size: 1.4rem;
  }

}