.noSchedules {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;

  &--center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center;
    height: 70vh;
  }


  &__title {
    font-size: 2.1rem;
    color: #4D4D4F;
  }

  &__text {
    margin-top: 1rem;
    font-size: 1.8rem;
    color: #666669;
  }
}