@import '../../../styles/styles.scss';

.stage {
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  height: 10rem;
  width: 10rem;
  padding: 0 1rem;

  @include tablet {

    @include desktop {
      height: 12rem;
      min-width: 12rem;
    }
  }

  &__image {
    width: 3.8rem;
    height: 3.8rem;
    margin-bottom: 0.8rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(342deg) brightness(102%) contrast(102%);
  }

  &__title {
    font-size: 1.2rem;
    line-height: 14px;
    text-align: center;
    color: white;

    @include desktop {
      font-size: 1.4rem;
    }
  }

  &--locked {
    cursor: not-allowed;

    .stage__image {
      filter: brightness(0) saturate(100%) invert(70%) sepia(3%) saturate(16%)
        hue-rotate(184deg) brightness(100%) contrast(85%);
    }

    .stage__title {
      color: #a1a1a1;
    }
  }

  &--active-lock {
    cursor: not-allowed;

    .stage__image {
      filter: brightness(0) saturate(100%) invert(54%) sepia(3%) saturate(16%)
        hue-rotate(184deg) brightness(85%) contrast(85%);
    }

    .stage__title {
      color: #828285;
    }
  }

  &--active {
    cursor: pointer;

    @include tablet{
      &:hover {
        background: rgba(8, 3, 17, 0.2);
        border: 0.1rem solid #bdbdff;
        box-shadow: 0px 4px 20px rgba(100, 41, 205, 0.7),
          inset 0px 0px 30px rgba(100, 41, 205, 0.7);
        backdrop-filter: blur(15px);
        border-radius: 1.1rem;
      }
    }
  }

  &--pending {
    .stage__title {
      color: $color-primary;
      max-width: 72px;
      max-height: 31px;
    }
  }

  &--completed {
    cursor: not-allowed;

    .stage__image {
      filter: brightness(0) saturate(100%) invert(70%) sepia(3%) saturate(16%)
        hue-rotate(184deg) brightness(85%) contrast(85%);
    }

    .stage__title {
      color: #a1a1a1;
    }
  }
  &--selected {
    position: relative;
    background: rgba(8, 3, 17, 0.2);
    border: 0.1rem solid #bdbdff;
    box-shadow: 0px 4px 20px rgba(100, 41, 205, 0.7),
      inset 0px 0px 30px rgba(100, 41, 205, 0.7);
    backdrop-filter: blur(15px);
    border-radius: 1.1rem;

    &-span {
      position: absolute;

      &--1 {
        top: -0.5rem;
        left: -0.5rem;
        transform: rotate(180deg);
      }
      &--2 {
        top: -0.5rem;
        right: -0.5rem;
        transform: rotate(270deg);
      }
      &--3 {
        bottom: -0.5rem;
        right: -0.5rem;
      }
      &--4 {
        bottom: -0.5rem;
        left: -0.5rem;
        transform: rotate(90deg);
      }
    }
  }
  &--show {
    z-index: 1000;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
      hue-rotate(342deg) brightness(102%) contrast(102%);
  }
}
