@import 'styles/_variables.scss';

.progressBarContainer {
  font-family: Poppins;
  color: $color-purple;
  font-size: 12px;
  font-weight: 400;
}

.fileName {
  font-size: 12px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  line-height: 18px;
}

.titleBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
