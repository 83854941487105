@import '../../../styles/styles.scss';

.onboardingModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 3.3rem 3.4rem;
  background-color: #DFDFFF;
  border-radius: 24px;
  z-index: 100;
  color: #6429CD;
  animation: fadeIn 0.3s ease-in-out forwards;

  &__headerCloseContainer {
    position: absolute;
    height: 1.7rem;
    width: 1.7rem;
    top: 2rem;
    right: 2rem;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &__headerTitle {
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 26px;

    &--dark {
      color: #f6f7fc;
    }
  }

  &__text {
    font-size: 1.5rem;
    line-height: normal;

    &--dark {
      color: #f6f7fc;
    }
  }

  &__buttonsCtn {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    padding-top: 2rem;

    button {
      margin: 0;
      padding: 0.6rem 2.3rem;
      border-radius: 100px;
      font-size: 1.6rem;
      font-weight: 400;
      cursor: pointer;
    }

    &--center {
      align-items: center;
      justify-content: center;
    }
  }

  .go-back-btn {
    color: #6429cd;
    border: 1px solid #6429CD;
    box-shadow: unset;
    transition: 0.3s ease;
    background-color: #DFDFFF;

    &:hover {
      box-shadow: 0px 0px 15px #6429cd,
        inset 0px 0px 10px rgba(189, 189, 255, 0.6);
    }
  }

  .leave-btn {
    background-color: transparent;
    border: 1px solid #f6f7fc;
    color: #f6f7fc;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 0px 15px rgba(189, 189, 255, 0.6),
        inset 0px 0px 30px rgba(189, 189, 255, 0.559);
    }
  }

  &__step {
    font-size: 1.4rem;
  }

  &--dark {
    margin: 0 auto;
    width: clamp(200px, 88%, 450px);
    text-align: center;
    background-color: #1F0D3F;
    transform: translateY(calc(50vh - 50%));
  }

  b {
    color: #6429CD;
  }
}

.modalOnboardingZero {
  justify-content: space-between;
  width: clamp(200px, 88%, 550px);
  transform: translateY(calc(50vh - 50%));
  height: clamp(100px, 100%, 280px);
  margin: 0 auto;
  text-align: center;

  p {
    color: #330072;
  }

  &>div:first-child {
    top: 3rem;
    right: 3.6rem;
  }

  &>div:nth-child(2) {
    padding-top: 3rem;
  }
}

.modalOnboardingOne {
  position: absolute;
  width: 400px;
  top: 5rem;
  left: 8rem;

  &--left {
    transform: translate(-50%, 1.5rem);
  }
}

.modalOnboardingTwo {
  position: absolute;
  width: clamp(200px, 100%, 400px);
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

.modalOnboardingThree {
  position: absolute;
  left: 100%;
  width: 400px;
  transform: translateY(-30%);
}

.modalOnboardingFour {
  position: absolute;
  left: 100%;
  width: 400px;
}

.modalOnboardingFive {
  position: absolute;
  right: 105%;
  top: 0;
  width: 380px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}