@import 'styles/styles.scss';

.error-boundary{

  &--bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: $background-gradient;
    padding: 1rem;
  }

  &-side{
    
    &__button{
      font-size: 1.6rem;
      margin-top: 3.4rem;
      font-weight: 600;
      z-index: 5;
    }

    &__container{
      background: rgba(10, 9, 12, 0.6);
      border: 1px solid #6429CD;
      border-radius: 25px;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    &__span{
      display: hidden;
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 50%;
      position: absolute;
      animation: span 2s;
      animation-fill-mode: forwards;

      &-container{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      &:nth-child(1){
        width: 41vw;
        height: 41vw;
      }
      &:nth-child(2){
        width: 48vw;
        height: 48vw;
      }
      &:nth-child(3){
        width: 55vw;
        height: 55vw;
      }
      &:nth-child(4){
        width: 63vw;
        height: 63vw;
      }
      &:nth-child(5){
        width: 72vw;
        height: 72vw;
      }
      &:nth-child(6){
        width: 82vw;
        height: 82vw;
      }
      &:nth-child(7){
        width: 93vw;
        height: 93vw;
      }
      &:nth-child(8){
        width: 105vw;
        height: 105vw;
      }
      &:nth-child(9){
        width: 118vw;
        height: 118vw;
      }
      &:nth-child(10){
        width: 133vw;
        height: 133vw;
      }
      &:nth-child(11){
        width: 147vw;
        height: 147vw;
      }

      @include tablet{

      &:nth-child(1){
        width: 10vw;
        height: 10vw;
      }
      &:nth-child(2){
        width: 15vw;
        height: 15vw;
      }
      &:nth-child(3){
        width: 20vw;
        height: 20vw;
      }
      &:nth-child(4){
        width: 58vw;
        height: 58vw;
      }
      &:nth-child(5){
        width: 67vw;
        height: 67vw;
      }
      &:nth-child(6){
        width: 78vw;
        height: 78vw;
      }
      &:nth-child(7){
        width: 90vw;
        height: 90vw;
      }
      &:nth-child(8){
        width: 97vw;
        height: 97vw;
      }
      &:nth-child(9){
        width: 109vw;
        height: 109vw;
      }
      &:nth-child(10){
        width: 120vw;
        height: 120vw;
      }
      &:nth-child(11){
        width: 132vw;
        height: 132vw;
      }
    }
      
    }

  }
  
}

@keyframes span {
  100%{
    transform: scale(2.5);
  }
}