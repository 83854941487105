@use 'styles/styles.scss' as *;

.progress-bar {
  width: 100%;
  height: 12px;
  display: flex;
  justify-content: flex-end;
  background: $color-gradient-from-light-to-purple;
  border-radius: 15px;
  border: 1px solid $color-primary;
  box-shadow: $shadow-primary;

  &__inside {
    height: 100%;
    border-radius: 15px;
    border: 1px 0px 1px 0px solid $color-primary;
    background-color: $color-light-purple;
    transition: width 1s ease;
  }
}
