@use 'styles/styles.scss' as *;

.container {
  background-color: $color-light-purple;
  border-radius: 25px;
  max-width: 1240px;
  margin: auto auto;
  width: 100%;

  @include laptop {
    margin: 45px auto;
  }

  @include auxiliaryDesktop {
    max-width: 1500px;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 700px;
  }

}
