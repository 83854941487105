@import '../../../styles/styles.scss';


.welcome-modal {
    &__wrapper {
        width: 90vw;
        background-color: $color-black;
        border-radius: 2.5rem;
        overflow: hidden;
        display: grid;
        grid-template-rows: 5.4rem min-content;
        filter: drop-shadow(0px 12px 24px rgba(100, 41, 205, 0.6));
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 20px rgba(189, 189, 255, 0.6);
            z-index: 2;
        }

        @include tablet {
            width: 45vw;
        }
    }

    &__header {
        background-color: $color-primary;
        z-index: 1;
    }

    &__information {
        color: $color-white;
        padding: 3.4rem 2.3rem 5rem 2.3rem;
    }

    &__button {
        height: 4rem;
        z-index: 3;
    }
}

.information {
    &__texts {
        display: grid;
        row-gap: 2rem;
    }

    &__bold {
        font-weight: 600;
    }

    &__title {
        font-weight: 700;
        font-size: 2.1rem;
    }

    &__description {
        line-height: 1.9rem;
        font-size: 1.4rem;
    }

    &__indication {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    &__button-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 3.9rem;
    }
}