@import 'styles/styles.scss';

.css-m10i78-container {
  opacity: 0.4;
}

.container-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__input {
    font-family: $font-poppins;
    font-size: 14px;
    height: auto;
    div {
      div {
        div {
          padding: 4px;
          svg {
            margin-right: 4px;
            border-radius: 100px;
            transform: scale(1.2);
            path {
              max-width: 100%;
              max-height: 100%;
              justify-content: center;
              transform: scale(100%);
              transform: -webkit-scale(1);
            }
          }
        }
      }
    }
  }

  &__options {
    display: flex;
    align-items: center;
  }
  input{
    min-width: 100px!important;
  }
}

.icon-li {
  width: 2rem;
  margin-right: 8px;
}

.subtext-error { 
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 1rem;
  letter-spacing: 0em;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #660019;
  path {
    fill: #660019;
  }
}

.input-subtext {
  svg {
    display: none;
  }
}

.subtext {
  height: 26px;
  margin-top: 0;
}
.custom-dropdown-indicator{
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out!important;
  
}
.custom-dropdown-indicator.open{
  transform: rotate(180deg);
}