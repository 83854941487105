@import './../../../styles/styles.scss';

.loading__page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $background-gradient;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  &-fullScreen {
    position: fixed;
    z-index: 20000;
  }
}