@import '~styles/_variables.scss';

.groupDependent {
  display: flex;
  flex-direction: column;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
}
.containerButtonsModal {
  button {
    color: #fff;
    font-size: 16px;
    background-color: transparent;
    border: none;
    padding: 10px 5px;
    cursor: pointer;
    &.primary {
      background: #6429cd;
      border-radius: 50px;
      width: 139px;
      margin-left: 10px;
      &:hover {
        box-shadow: 0px 0px 15px #6429cd,
          inset 0px 0px 10px rgba(189, 189, 255, 0.6);
        cursor: pointer;
      }
    }
  }
}
.dependentsRelationShip {
  button {
    width: 125px;
    margin: auto;
    height: 40px;
    &:disabled {
      background: rgba(51, 0, 114, 0.6);
      color: linen;
      opacity: 1;
    }
  }
}

.titleInformation {
  color: rgba(51, 0, 114, 1);
  font-size: 21px;
  font-weight: 700;
  padding: 10px 0px 25px 0px;
}

.buttonDisabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.widthcalendar {
  width: auto !important;
}

.uploadGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
}

.descriptionDocuments {
  font-size: 12px;
  text-align: center;
  margin: auto;
  line-height: 15.6px;
  min-height: 46px;
}
.hidden {
  display: none;
}
.show {
  display: block;
}
