@import '../../../styles/styles.scss';

.activityAtomText {
  color: $color-purple;
  font-size: 1.4rem;
  margin-top: 1rem;
  text-align: left;
  line-height: 130%;
  
  @include from($x-large) {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  @include from($high-quality) {
    font-size: 1.9rem;
    margin-bottom: 1.5rem;
  }
  &__text-help{
    font-weight: 600;
  }
}
