@use '../../../styles/styles.scss';
@import '../../../styles/variables';
@import 'styles/styles';

.reset-pass {

  &__button{
    position: absolute;
    top: 0;
    left: 0;
    margin: 1.9rem 0 0 1.6rem;
    @include laptop{
      display: none;
    }
    @include laptop-height{
      display: none;
    }
  }
  
  
  &__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-gradient;
    width: 100%;
    height: 100vh;

    &-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
    }
  }
  
  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @include laptop{
      margin-top: 4rem;
    }

    img{
      width: 45vw;
      height: 100%;

      @include laptop{
        width: 20rem;
        height: 7rem;
      }

      @include laptop-height{
        height: 5rem;
      }
    }
  
  }

  &__icon{
    margin-bottom: 3vh;
    margin-top: 3vh;
    
    @include laptop{
      margin-top: 2rem;
    }

    img{
      width: 15vw;
      height: 100%;

      @include laptop{
        width: 15rem;
        height: 7rem;
      }
      
      @include laptop-height{
        height: 6rem;
      }
    }
  }
  
  &__form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;

    &-title{
      font-size: 2.1rem;
      font-weight:600;
      margin-bottom: 1vh;
      color: $color-white;
      text-align: center;
      line-height: 29.4px;

      @include laptop{
        font-size: 1.8rem;
      }
    }
    
    &-description{
      width: 32rem;
      color: $color-white;
      font-size: 1.6rem;
      font-weight: 400;
      text-align: center;
      line-height: 140%;
      font-style: normal;
      margin-bottom: 3rem;

      @include laptop{
        width: 34rem;
        margin-bottom: 2rem;
        margin-top: 0rem;
        font-size: 1.4rem;
      }
    }

    &-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;

      @include laptop{
        width: 80%;
      }
    }

    &-label{
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      width: 100%;


      &-wrapper{
        display: flex;
        flex-direction: row;
        color: $color-white;
        border: 1px solid #F6F7FC;
        border-radius: 100px;
        width: 100%;
        background-color: transparent;
        
        &:hover {
          color: $color-white;
          box-shadow: 0 0 10px $color-white;
        }

        &--error, &--error:focus, &--error:hover{
          border: .1rem solid $color-danger;
          box-shadow: 0 0 10px $color-danger inset;
        }

        &--isValid, &--isValid:focus, &--isValid:hover{
          border: .1rem solid #00B880 ;
          box-shadow: 0 0 10px #00B880 inset;
        
        }

      }

    }

    &-input{
      color: $color-white;
      padding: 2rem 1rem;
      height: 2vh;
      width: 100%;
      border: none;
      display: flex;
      justify-content: center;
      background: transparent;
      font-size: 1.4rem;
      border-radius: 10rem;
      
      &::placeholder{
        color: #828285;
      }

      &:focus {
        outline: none;
        background: transparent;
      }
    }
  }
}

.butn {
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  background-color: $color-white;
  margin-right: .5rem;
  margin-top: auto;
  margin-bottom: auto;
  width: 4rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
 
  @include laptop{
    cursor: pointer;
    margin-right: .5rem;
  }
  @include laptop-height{
    margin-right: .5rem;
  }

  img{
    width: 1.7rem;

    @include laptop{
      width: 1.7rem;
    }
  }

}
.btn{
  width: 11.4rem;
  height: 3.2rem;
  font-size: 1.4rem;
}

.error-password {
  display: flex;
  align-items: center;
  width: 25rem;
  color: $color-danger;
  margin-top: .8rem;
  margin-bottom: 1rem;
  text-align: start;
  line-height: 140%;
  font-weight: 400;
  margin-right: 2rem;

  @include laptop{
    font-weight: 600;
    margin-left: -2rem;
  }
}

.error-icon{
  width: 1.5rem;
  margin-bottom: .7rem;
  margin-right: .5rem;
  color: $color-danger;

  @include laptop{
    margin-bottom: .8rem;
  }
}