@import 'styles/_variables';

.Card__BackArrow {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  border: 1px solid #330072;
  border-radius: 10px;
  font-family: Poppins;
  color: $color-purple;
  position: relative;
  background-color: rgba(51, 0, 114, 0.05);
}

.cardContainerError {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  border: 1px solid $color-danger;
  border-radius: 10px;
  font-family: Poppins;
  color: $color-purple;
  position: relative;
  background-color: rgba(51, 0, 114, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(237, 0, 57, 1) inset;
}

.name {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: $color-purple;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1280px) {
    max-width: 150px;
  }
  
}
.bull {
  margin: 0px 5px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.deleteIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-left: 5px;
}

.mapContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: 20px;
}

.mapNames {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.filesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 35px;
}

.separator {
  width: 261px;
  height: 1px;
  background-color: #330072;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardError {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  font-size: 10px;
  color: $color-danger;
  margin-top: 8px;
}

.FileUploaderContainer {
  display: flex;
  flex-direction: column;
  min-height: 181px;
  &__tooltip {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0px 8px;
    max-width: 90%;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  &__height {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 46px;
  }
  &__description {
    display: flex;
  }
  &__hidden {
    display: none;
  }
  &__title {
    font-weight: 700;
    color: $color-purple;
    min-height: 16px;
    text-align: center;
    font-size: 14px;
  }

  &__ButtonsContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 10px 0 16px 0px;
    &--hidden {
      visibility: hidden;
    }
  }

  &__DownloadButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 32px;
  }

  &__DownloadButton {
    border: 1px solid #330072;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.5s;

    &--disabled {
      opacity: 40%;
    }
  }

  &__UploadButton {
    width: auto;
    height: 32px;
    span {
      width: 100%;
      padding: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.buttonLoadModal{
  font-family: Poppins;
  width: 131px;
  height: 40px;
  border-radius: 50px;
  color: white;
  background-color: #330072;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}
.listItem{
    list-style: unset;
    margin-left: 1.1rem;
    font-size: 1.6rem;
    color: #330072;
    line-height: normal;
}
