@import '../../../styles/styles.scss';

.white{
  color:white;
}

.textIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 6.6rem;
  border-radius: 0 0 2.5rem 2.5rem;
  color: $color-black;

    &--info {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0.3rem 0;
        background: #9610FF;
        box-shadow: 0px 8px 30px rgba(32, 32, 142, 0.79);
        z-index: 0;
        height: min-content;
        img{
          filter: invert(1);
        }
        @include laptop {
          padding: 1.6rem 0;
        }
    }

    &--warning {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 8rem;
        background: #FFCA10;
        box-shadow: 0px 8px 30px #997700, inset 0px 0px 30px #FFEEB3;
        z-index: 2;
    }

  &__img {
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
    margin: 0 2.6rem;

    &--mobile {
      margin-right: 1rem;
    }

    &--filter {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }


  }

  &__img_info {
    width: 3rem;
    height: 3rem;
    margin: 0 2.6rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }

  &__link {
    text-decoration: underline;
  }

  &__container__text {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    @include laptop {
      margin-right: 2.6rem;
    }
  }

  &__text {
    font-size: 1.2rem;
    margin-bottom: .5vh;
    display: inline;
    line-height: 130%;
    color: $color-purple;

    @include laptop {
      font-size: 1.4rem;
    }

    &-container {
      display: flex;
      gap: .5rem;
    }

    &--bold {
      font-size: 1rem;
      font-weight: 600;
    }

    &-white{
      color: white;
    }

    &-bolder{
      font-weight: bold;
    }

    &--link {
      color: $color-tertiary;
      text-decoration: underline;
    }
  }

  &__info {
    font-size: 1.2rem;
    color: $color-black;
    line-height: 140%;

    @include laptop {
      font-size: 1.4rem;
    }

    &--white {
      color: $color-white;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 140%;

    @include laptop {
      font-size: 1.6rem;
    }

    &--white {
      color: $color-white;
    }
  }
}