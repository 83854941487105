.info-tooltip {
  position: absolute;
  background-color: #DFDFFF;
  width: 38rem;
  right: 0;
  border-radius: 24px;
  z-index: 200;
  color: #6429CD;
  font-size: 1.4rem;
  padding: 2.5rem 4rem;
  transform: translate(105%, 0);
  animation: fadeIn 0.3s ease-in-out forwards;

  &__title {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  &--help {
    transform: translate(105%, 45%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}