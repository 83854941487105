@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.input-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0px 0px 10px 0px hsl(162deg, 100%, 46%) inset;
  }

  input {
    height: 40px;
    width: 100%;
    background: #6429cd14;
    border: 1px solid #6429cd;
    border-radius: 100px;
    color: #330072;
    font-size: $mobile-font;
    padding: 0 1.2rem;

    &:focus {
      border: 1px solid #330072;
      box-shadow: 0px 0px 15px #6429cd;
      outline: none;
    }

    &.input-error {
      background: rgba(237, 0, 57, 0.08);
      border: 1px solid #660019;
      box-shadow: inset 0px 0px 10px #ed0039;

      &::placeholder {
        color: #6429cd66;
      }
    }

    &.input-success {
      border: 1px solid hsla(162, 100%, 21%, 1);
      background: hsla(162, 100%, 36%, 0.08);
      box-shadow: 0px 0px 10px 0px hsla(162, 100%, 46%, 1) inset;
      color: hsla(162, 100%, 21%, 1);
    }

    &.disabled {
      background: rgba(100, 41, 205, 0.08);
      border: 1px solid #330072;
      border-radius: 100px;
      color: #330072;
      opacity: 0.5;
    }

    &::placeholder {
      color: #6429cd66;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .input-subtext,
  .subtext-error {
    margin-top: 1rem;
    letter-spacing: 0em;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }

  .subtext {
    height: 26px;
    margin-top: 0;
  }

  .input-subtext {
    color: #330072;

    svg {
      display: none;
    }
  }

  .subtext-error {
    display: flex;
    align-items: center;
    color: $color-error-700;
    svg {
      margin-right: 5px;
    }
  }

  @include from($x-large) {
    input {
      height: 40px;
      font-size: $mobile-font;
    }
  }

  @include from($high-quality) {
    input {
      height: 40px;
      font-size: $hq-font;
    }
  }
}
