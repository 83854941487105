@import 'styles/_variables';

.UploadBtn {
  font-family: Poppins;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  color: white;
  background-color: $color-purple;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;

  &--large {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px;
  }
  &--disabled {
    opacity: 0.5;
    cursor: move;
  }
}
