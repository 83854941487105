@import '../../../styles/variables.scss';

.tab-titles {
  display: flex;
  button {
    font-size: 1.5rem;
    position: relative;
    display: flex;
    padding: 15px 12px;
    border: none;
    color: $color-purple;
    cursor: pointer;
    border-bottom: 1px solid $color-primary;
    outline: none;
    background-color: transparent;
    transition: 0.3 ease-in-out;
    gap: 0.5rem;
  }
}

.tab-titles button.active {
  color: $color-primary;
  font-weight: bold;
  transition: 0.3 ease-in-out;
}

.tab-titles button.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 5px;
  background: $color-primary;
  box-shadow: 0px -5px 15px 10px #daccf3;
  border-radius: 5px;
}

.tab-panel {
  display: none;
}

.tab-panel.active {
  display: block;
}
.amount {
  font-size: 1.5rem;
  font-weight: 500;
  width: 2.1rem;
  height: 2.1rem;
  color: white;
  background-color: $color-purple;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
