@import '../../../styles/styles.scss';

.template-authentication {
  background: $background-gradient;
  height: 100vh;
  width: 100%;
  
  @media (min-width: 400px){
    max-height: 1000px;
    overflow: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100vh;

    &__icon{

      @include laptop-height{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include laptop{
      border-radius: 0;
    }
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
   
    @include laptop-height {
      width: 50%;
    }
  }

  &__logo-pragma {
    text-align: center;

    img {
      width: 16rem;

      @include laptop{
        width: 19rem;
      }
    }
  }

  
  &__icon {
    width: 6rem;
    height: 14rem;

    @include laptop-height {
      height: 10rem;
    }
  }

  &__title {
    color:  $color-white;
    width: 40rem;
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;

    @include laptop{
      font-style: normal;
      font-size: 2rem;
      line-height: 100%;
    }

    @include laptop-height{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
    }
  }

  &__subtitle {
    width: 30rem;
    color: white;
    font-size: 1.6rem;
    margin-bottom: 30px;
    text-align: center;
    line-height: 22.4px;
    font-weight: 400;

    @include laptop{
      width: 45rem;
      font-weight: 400;
      margin-bottom: .8rem;
    }

    @include laptop-height{
      width: 40rem;
    }
  }

}

.placeholder-color {
  &::placeholder {
    color: #d2d2d6 !important;
  }
}

