@import '../../../styles/styles.scss';

.inactiveUser {
  background: rgba(10, 9, 12, 0.6);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0.1rem solid #6429cd;
  border-radius: 2.5rem;

  &__info {
    width: 80%;
  }

  &__title {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2.7rem;
  }

  &__text {
    color: #fff;
    margin-bottom: $padding-x;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  &__textParkedVip {
    color: #fff;
    margin-bottom: $padding-x;
    font-size: 1.6rem;
    line-height: 2.5rem;
    text-align: center;
  }

  &__study {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
  }

  &__wrapperStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.7rem;
  }

  &__image {
    width: 100%;
    margin: auto;
    margin-bottom: 0.7rem;

    &--hiredIcon {
      margin-bottom: 0.7rem;
    }
  }

  &__link {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #bdbdff;
    text-decoration: underline;
  }
}
.buttonParked {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  gap: 10px;

  background: #330072;
  border-radius: 9999px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  text-align: center;

  color: #f6f7fc;

  width: 200px;
  height: 50px;
}
