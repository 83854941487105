@import 'styles/styles.scss';

.file-loading {
  color: #330072;
  background: rgba(51, 0, 114, 0.05);
  border: 1px solid #330072;
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin: 0.3rem 0;

  &__size {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 152%;
    width: 145px;

    @include laptop {
      width: 320px;
    }
  }

  &__icon {
    grid-area: icon;
    margin: 0 9px 0 12px;
    width: 18px;
    height: 22px;

    @include laptop {
      width: 32px;
      height: 32px;
    }
  }

  &__name-file {
    display: flex;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-purple;
    font-size: 1.2rem;
    margin-top: 0 !important;
    font-weight: 700;
    max-width: 300px;

    @include laptop {
      width: 100%;
    }

    &--link {
      text-decoration: underline;
      width: 145px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 1.2rem;

      @include laptop {
        width: auto;
      }
    }

    &--url {
      font-weight: 400;
    }
  }

  .bar-loading {
    position: relative;
    grid-area: bars;
    background-color: #00000029;
    border-radius: 10px;
    margin-top: 1rem;
    width: 120px;
    height: 7px;

    @include laptop {
      width: auto;
    }

    &__progress {
      border-radius: 10px;
      background: #330072;
      box-shadow: 0px 0px 4px rgba(100, 41, 205, 0.6);
      height: 100%;
      transition: all 2s;
      max-width: 100%;
      width: 100%;

      &--avance {
        animation-name: avance;
        animation-duration: 2s;
      }
    }

    &__porcentaje {
      position: absolute;
      right: -40px;
      top: -8px;
      font-size: 1.3rem;
      line-height: 152%;
    }

    @include laptop {
      flex-direction: column;
    }
  }

  &__close {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 25px;
    font-weight: 300;
    justify-content: center;
    position: absolute;
    right: 0;

    @include tablet {
      align-items: flex-start;
      margin-right: 10px;
    }

    @include mobile {
      margin-right: 10px;
      font-size: 40px;
      margin-bottom: 0;
    }

    @include laptop {
      margin-right: 10px;
    }
  }
}
.file-loading__documents {
  background: none;
  border: none;
  display: flex;
  gap: 10px;
  min-width: 180px;
  width: auto;
  @extend .file-loading;
}

.iconDelete {
  height: 17px;
  width: auto;
}

.pdf_name {
  display: flex;
  gap: 10px;
  width: 100%;
}

@keyframes avance {
  0% {
    width: 0%;
  }

  10% {
    width: 10%;
  }

  20% {
    width: 20%;
  }

  30% {
    width: 30%;
  }

  40% {
    width: 40%;
  }

  50% {
    width: 50%;
  }

  60% {
    width: 60%;
  }

  70% {
    width: 70%;
  }

  80% {
    width: 80%;
  }

  90% {
    width: 90%;
  }

  100% {
    width: 100%;
  }
}

.loader_bar {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
