.buttonUploader {
  align-items: center;
  background: var(--primary-60-purple-primary-purple-700, #330072);
  border: none;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  gap: 10px;
  height: 32px;
  justify-content: center;
  line-height: 140%;
  padding: 16px;
  width: auto;

  span {
    display: flex;
    gap: 1rem;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}
.classButtonCenter {
  justify-content: center !important;
  display: flex !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
