@import '../../../styles/styles.scss';

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  width: 100%;

  &__error-paragraph{
    display: flex;
    align-items: center;
    width: 100%;
    color: #FFB8C9;
    font-size: 1.2rem;
    text-align: left;
    margin-top: .9rem;
    text-align: start;

  }
  
  &__error-icon{
    margin-right: .7rem;
  }
  
  &__input-text{
    position: relative;
    width: 100%;

    @include laptop{
      width: 85%;
    }

    &--element{
      border: 1px solid #e8e8ed;
      padding: 16px 14px 15px 14px;
      border-radius: 10rem;
      width: 100%;
      height: 4rem;
      background-color: rgba(255, 255, 255, 0);
      color: #d2d2d6;
      font-size: 1.4rem;
      font-weight: 400;

      @include desktop{
        height: 5rem;
      }

      &-error{
        border: .1rem solid #ED0039;
        box-shadow: inset 0 0 10px #ED0039;
        width: 100%;

        &:hover{
          box-shadow: inset 0 0 10px #ED0039 !important;
        }
      }

      &::placeholder{
        color: #828285;
      }

      &:hover{
        box-shadow: 0 0 7px white;

        &::placeholder{
          color: $color-white;
        }
      }

      &:focus {
        outline: none;
      }
    }

    &--button{
      position: absolute;
      top: 2rem;
      transform: translateY(-50%);
      right: 5px;
      border: none;
      background-color: #ffffff;
      border-radius: 50%;
      height: 3.2rem;
      width: 3.2rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      
      &-error{
        position: absolute;
        top: 2rem;
        transform: translateY(-50%);
        right: 5px;
        border: none;
        background-color: #ED0039;
        border-radius: 50%;
        height: 3.2rem;
        width: 3.2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include desktop{
        height: 3.9rem;
        width: 3.9rem;
        top: 2.4rem;

        &-error{
          height: 3.9rem;
          width: 3.9rem;
          top: 2.4rem;
        }
      }
      
      &:hover ~ .input-text--element{
          box-shadow: 0 0 7px white;
          &::placeholder{
            color: $color-white;
          }
      }

      & img {
        position: relative;
        height: 1.4rem;
        width: 1.4rem;

        @include desktop{
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }
  }

  .input-checkbox {
    &__label {
      font-size: 14px;
      color: $color-secondary;
      text-align: center;
    }

    &__box {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      position: relative;
    }

    &__dumb {
      width: 20px;
      height: 20px;
      border: 1.5px solid #707070;
      border-radius: 5px;
      margin-right: 9px;
      visibility: hidden;
    }

    &__box {
      .mark {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 1.5px solid #471e87;
        border-radius: 5px;
        margin-right: 9px;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          display: none;
        }
      }

      input:checked~.mark {
        background-color: #471e87;
      }

      input:checked~.mark:after {
        display: block;
      }

      .mark {
        &:after {
          left: 5.5px;
          top: 1.5px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__link-text {
    text-align: center;
    align-self: center;
    border: none;
    color: $color-white;
    background-color: transparent;
    width: 100%;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;

    &:hover{
      background-color: transparent;
      border: none;
    }
  }

  &__link-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    font-size: 2.2rem;
  }
}

.pos-rel {
  position: relative;
}


.btn{
  width: 10.9rem;
  height: 4rem;
  font-size: 1.6rem;
}
