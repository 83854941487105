@import '../../../styles/styles.scss';

.stagesRender {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  justify-items: center;
  align-items: flex-start;
  margin-bottom: 4.3rem;

  @include tablet{
    grid-template-columns: repeat(3, 1fr);
  }

  &--hidden {
    display: none;
  }
}

.btn_container {
  display: flex;
  justify-content: center;
  align-items: center;  
  width: fit-content;
  height: fit-content;
}
