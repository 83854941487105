.containerButtonsModal {
    button {
      color: #fff;
      font-size: 16px;
      background-color: transparent;
      border: none;
      padding: 10px 5px;
      cursor: pointer;
  
      &.primary {
        background: #6429cd;
        border-radius: 50px;
        width: 139px;
        margin-left: 10px;
  
        &:hover {
          box-shadow: 0px 0px 15px #6429cd,
            inset 0px 0px 10px rgba(189, 189, 255, 0.6);
          cursor: pointer;
        }
      }
    }
  }