.AddButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: hsla(262, 67%, 48%, 1);
  padding: 0;
  margin: 0;
  transition: all 0.5s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 12px;

  &--expanded {
    width: 150px;
    gap: 10px;
  }

  &--withAnimation {
    img {
      transition: all 0.5s ease;
    }

    span {
      transition: all 0.5s ease;
      transform: translateX(-100px);
      opacity: 0;
      position: absolute;
    }

    &:hover {
      width: 150px;
      gap: 10px;

      &:hover span {
        transform: translateX(3px);
        opacity: 1;
      }

      &:hover img {
        transform: translateX(-51.5px);
      }
    }
  }
}
