@import 'styles/styles.scss';

.form-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid $color-purple;
  border-bottom-color: transparent;
  border-radius: 16px 16px 0 0;
  padding: 24px;
  color: $color-purple;
  width: 100%;
  width: fit-content;
  text-wrap: nowrap;
  position: relative;

  @media (max-width: 1280px) {
    padding: 24px 9px;
  }
  
  &--selected {
    font-weight: 700;
    border: 2px solid $color-purple;
    border-bottom-color: #bdbdff;
    box-shadow: 0px -2px 5.800000190734863px 0px #6429cd;
  }

  &--disabled {
    cursor: not-allowed;
    color: hsla(262, 67%, 48%, 0.4);
    border: 1px solid hsla(262, 67%, 48%, 0.4);
  }

  &__icon {
    &--error {
      border-radius: 100px;
      width: 24px;
      height: 24px;
      filter: drop-shadow(0px 4px 4px #eb00371f)
        drop-shadow(0px 6px 12px #eb003766);
      background: #ed0039;
    }

    &--success {
      border-radius: 100px;
      width: 24px;
      height: 24px;
      box-shadow: 0px 12px 24px 0px hsla(162, 100%, 46%, 0.4);
    }

    &--default {
      border-radius: 100px;
      width: 24px;
      height: 24px;
      border: 1px solid $color-purple;
    }
  }

  &__content {
    font-size: 21px;
  }
  &--locked {
    cursor: default !important;
    pointer-events: none !important;
  }
}
