@import '../../../styles/styles.scss';

.activityAtomTextItem {
  color: $color-purple;
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 130%;
  text-align: left;
  font-weight: 400;

  @include from($x-large) {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  @include from($high-quality) {
    font-size: 1.9rem;
    margin-bottom: 1.5rem;
  }

  &__link{
    color: $color-primary;
    text-decoration: underline;
  }
}
