@import '../../../styles/styles.scss';

.barsLoader {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25rem;
  }

  &__barContainer {
    margin: 0.5rem 0;
    width: 100% !important;
  }

  &__percent {
    align-self: flex-start;
  }

  &__fileInfo {
    display: flex;
    align-self: flex-start;
    max-width: 100%;
  }

  &__fileName {
    max-width: 70%;
  }
}

.bar-loading {
  position: relative;
  grid-area: bars;
  background-color: #00000029;
  border-radius: 10px;
  position: relative;
  margin-top: 1rem;
  width: 170px;
  height: 7px;

  @include laptop {
    width: auto;
  }

  &__progress {
    border-radius: 10px;
    background: #330072;
    box-shadow: 0px 0px 4px rgba(100, 41, 205, 0.6);
    height: 100%;
    transition: all 2s;
    max-width: 100%;
    width: 100%;

    &--avance {
      animation-name: avance;
      animation-duration: 2s;
    }
  }

  &__porcentaje {
    color: $color-purple;
    font-size: 1.3rem;
    line-height: 152%;
  }

  @include laptop {
    flex-direction: column;
  }
}
