@import '../../../styles/variables';
@import '../../../styles/styles.scss';

.restore-password-form {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  
  .error-form {
    text-align: start;
    display: flex;
    align-items: center;
    line-height: 140%;
    color: $color-danger;
    margin-top: 0.8rem;
  }

  .error-icon {
    width: 1.5rem;
    margin-right: .8rem;
    margin-top: .5rem;
  }

  .input-text {
    width: 26rem;
    
    @include laptop-height{
     width: 28rem;
    }

    &__element {
      padding: 2rem 1rem;
      height: 2vh;
      width: 100%;
      font-size: 1.3rem;
      margin-top: 1rem;
      color: $color-white;
      font-weight: 400;
      border-radius: 10rem;
      border: 1px solid #F6F7FC;
      background-color: rgba(100, 41, 205, 0.08);

      &::placeholder {
        color: #828285;
      }

      &:focus {
        border: 1px solid #F6F7FC;
        outline: none;
      }

      &:hover {
        color: $color-white;
        box-shadow: 0 0 10px $color-white;

        &::placeholder {
          color: $color-white;
        }
      }
    }

    &__element--error,
    &__element--error:focus {
      border: .1rem solid $color-danger;
      box-shadow: 0 0 10px $color-danger inset;
    }

    &__element--isValid,
    &__element--isValid:focus {
      border: .1rem solid #00B880 ;
      box-shadow: 0 0 10px #00B880 inset;
    }
  }

  &__remember {
    font-size: 1.5rem;
    margin-top: 3rem;
    font-weight: 400;
    line-height: 2.08rem;
  }
  .secondary-color{
    color: $color-white;
    font-size: 1.7rem;
    font-weight: 400;
  }

  &__button-link {
    background-color: transparent;
    border: 0;
    margin-top: 1vh;
    text-decoration: underline;
    font-size: 1.7rem;
    color: $color-white;
    text-align: center;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
  }
}

.bttn {
  width: 9.9rem;
  height: 4rem;
}

.bttn__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
