@import 'styles/styles.scss';

.checkboxes {
  align-items: center;
  color: #330072;
  display: flex;
  font-size: 14px;
  gap: 10px;
  justify-content: center;
}

.docs__Separator {
  border-bottom: 1px solid #330072;
}

.check {
  cursor: pointer;

  &__checked {
    border: 1px solid $color-purple;
    background-color: rgba(100, 41, 205, 0.6);
    border-radius: 100%;
    display: inline-block;
    height: 2em;
    position: relative;
    transition: all 0.7s;
    width: 2em;
    &::after {
      border-radius: 100%;
      background: radial-gradient(
        circle,
        rgba(102, 44, 206, 1) 0%,
        rgba(135, 100, 225, 1) 100%
      );
      content: '';
      height: 1em;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s ease;
      width: 1em;
    }
  }
}

.check input:checked ~ span {
  background-color: rgb(106 51 209);
  box-shadow: inset 0px 4px 6px 7px #9c88ea;
  border: 1px solid #6429cc;
}

.check input:checked ~ span::after {
  opacity: 1;
}

.cardUploader {
  align-items: center;
  padding: 2.2rem 0.6rem;
  background: rgba(51, 0, 114, 0.05);
  border-radius: 10px;
  border: 1px solid var(--primary-60-purple-primary-purple-700, #330072);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-start;
  min-height: 150px;
  position: relative;
  grid-column: span 1;

  &__tooltipContainer {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
  }

  &__fileLoadingContainer {
    max-width: 80%;
  }

  &__fileUploaderContainer {
    margin-top: 3.2rem;
  }

  &__fileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1.6rem;
  }

  &__fileName {
    font-size: 1.2rem;
    font-weight: 700;
    color: $color-purple;
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  &__textNoEllopsis {
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    color: $color-purple;
    text-align: center;
  }

  &__fileSize {
    font-size: 1.2rem;
    color: $color-purple;
  }

  &__fileDelete {
    cursor: pointer;
  }

  &__separator {
    width: 80%;
    background-color: $color-purple;
    height: 1px;
    margin-top: 1.6rem;
  }

  &__arrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: $color-purple;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
  }
}

.cardUploader__header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: center;
}

.cardUploader__title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-60-purple-primary-purple-700, #330072);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
  text-align: center;
  min-height: 45px;
}

.notUploaded {
  border: 1px solid var(--Error-900, #660019);
  background: var(--Opacity-Error-500-Opacity-100, rgba(237, 0, 57, 0.08));
  box-shadow: 0px 0px 10px 0px #ed0039 inset;
}

.uploaded {
  border: 1px solid var(--Success-900, #006b4b);
  background: rgba(0, 184, 128, 0.08);
  box-shadow: 0px 0px 10px 0px #00eca5 inset;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

.classMarginsWfull {
  min-width: 100% !important;
}
