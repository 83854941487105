@import '../../../styles/styles.scss';

.section-container {
  display: flex;
  flex-direction: column;
  border-radius: 2.5rem;
  background: #bdbdff;
  margin: 0 5vw 130px 1.6rem;
  box-shadow: inset 0px 4px 80px rgba(100, 41, 205, 0.3);
  padding: 4.4rem;
  position: relative;

  @media (max-width: 1280px) {
    padding: 4.4rem 20px 4.4rem 4.4rem;
  }

  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 1.1rem;
  }

  &__go-back {
    cursor: pointer;
  }

  &__title {
    margin-left: 1.2rem;
    font-size: 3rem;
    font-weight: 700;
    color: $color-purple;
  }

  &__subtitle {
    font-size: 1.6rem;
    color: $color-purple;
    line-height: 2rem;
  }

  header {
    margin-bottom: 1.2rem;
  }

  @include tablet {
    margin: 0 5vw 30px 5vw;
    padding: 4rem;
  }

  @include laptop {
    margin: 0 5vw 79px 5vw;
    padding: 4rem;
  }

}

.footer-section-container {
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;

  @include laptop {
    padding-inline: 5%;
  }

  @include desktop {
    padding-inline: 6.5%;
  }

  @include tablet {
    padding-inline: 4%;
    margin: 0 auto;
  }
}