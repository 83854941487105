@import './../../../styles/styles.scss';

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
  
  &__ellipsis {
    display: inline-block;
    position: relative;
    &__full{
      height: 100%;
    }
  }
}