@import '../../../styles/styles.scss';

.notificationPopUp {
  min-height: 6.5rem;
  width: 90vw;
  display: flex;
  background: rgba(100, 41, 205, 0.05);
  border: 0.1rem solid $color-primary;
  box-shadow: 0px 3px 6px #505f6c10;
  box-shadow: inset 0px 0.4rem 8rem rgba(100, 41, 205, 0.25);
  border-radius: 1.5rem;
  position: relative;
  overflow: hidden;

  @include laptop {
    width: 100%;
  }

  &__container-image {
    width: 7.2rem;
    height: 100%;
    display: flex;
    flex: none;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1.4rem;
    background: $color-tertiary;

    &--danger {
      background: $color-danger;
    }
  }

  &__image {
    width: 3.6rem;
    filter: invert(20%) sepia(65%) saturate(7176%) hue-rotate(263deg)
    brightness(45%) contrast(102%);
  }

  &__texts-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.4rem 2.5rem 1.4rem 2.5rem;

    &--noText {
      padding: 1.4rem 2.5rem 4rem 2.5rem;
    }
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    line-height: 1.8rem;
    height: 100%;
  }

  &__showMore {
    font-size: 1.4rem;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    color: white;
    text-decoration: underline;
    margin: 0 1.5rem 1.5rem 0;

    &-text {
      color: white;
      font-size: 1.4rem;
      line-height: 1.8rem;

      & a {
        color: white;
        font-size: 1.4rem;
      }
    }
  }

  &__arrow-wrap {
    width: min-content;
    flex: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1.4rem;
    padding-right: 1.4rem;
  }

  &__arrowIcon {
    width: 1.6rem;
    color: white;
    transition: 0.4s ease;

    &--up {
      transform: rotate(180deg);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
