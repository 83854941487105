@import 'styles/_variables';

.closeButton {
  position: absolute;
  right: 22px;
  top: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.yellow {
  background-color: #ffca10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.red {
  background-color: red;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.green {
  background-color: #00b880;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.alert_modal_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  background-color: $color-black-modals-background;
  box-shadow: 0px 8px 40px rgba(100, 41, 205, 0.6),
    inset 0px 0px 30px rgba(100, 41, 205, 0.5);
  border: solid 1px $color-primary;
  border-radius: 25px;
  color: white;
  width: 395px;
  height: 325px;
  position: relative;

  &__icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__iconSvg {
    filter: invert(96%) sepia(97%) saturate(14%) hue-rotate(331deg) brightness(106%) contrast(100%);
  }

  &__title {
    font-weight: 400;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    max-width: 90%;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 90%;
    margin: 5px 0px 15px;
  }

  &__children {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
  }
}