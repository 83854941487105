@import '../../../styles/styles.scss';

.containerPicker{
    display: flex;
    flex-direction: row;
    gap: 16px;
    @media (max-width: $xx-large) {
      gap: 8px;
    }
}
.picker{    
    align-items: center;
    border-radius: 43px;
    border: 1.5px solid #330072;
    color: $color-purple;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 85px;
    justify-content: center;
    width: 50px;

    &__active{
        background: var(--primary-60-purple-primary-purple-700, #330072);
        box-shadow: 0px 12px 24px 0px rgba(100, 41, 205, 0.40), 0px 4px 24px 0px rgba(100, 41, 205, 0.24);
        color: white;
    }

    &__text{
        font-size: 16px;
    }
      
    &__day{
        font-size: 21px;
    }

    &__hour{
        font-size: 13px;
        height: 50px;
        width: 143px;
    }

    &:hover{
        background: var(--primary-60-purple-primary-purple-500, #6429CD);
        border: none;
        box-shadow: 0px 0px 20px 0px rgba(189, 189, 255, 0.60) inset, 0px 12px 24px 0px rgba(100, 41, 205, 0.60);
        color: white;
    }

    &:focus{
        box-shadow: 0px 12px 24px 0px rgba(100, 41, 205, 0.40), 0px 4px 24px 0px rgba(100, 41, 205, 0.24);
        background: var(--primary-60-purple-primary-purple-700, #330072);
        color: white;
    }
    
  
}