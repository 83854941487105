.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block: 2rem;

  &__large {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1%;
    div {
      border-top: 1px solid white;
      width: 100%;
    }
  }
  &__large--dark {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;

    .line-container {
      width: 100%;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      justify-content: center;
    }

    .purple-line {
      background-color: #ab88e7;
      width: 98%;
      height: 1px;
    }

    .white-line {
      background-color: #330072;
      width: 2%;
      height: 2px;
      max-width: calc(100% - 30px);
    }
  }
}

.footer--dark {
  justify-content: center;
}
