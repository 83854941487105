@import '../../../styles/styles.scss';

.container-loader {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include laptop {
    height: 45vh;
  }

  @include desktop {
    height: 60vh;
  }
}

.container-form {
  width: 100%;
  .form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 3.2rem 0 5.6rem 0;

    @media (min-width: $xx-large) {
      margin: 6.4rem 0 6.4rem 0;
    }

    .section-form {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: 100%;
      @media (min-width: $xx-large) {
        gap: 31px;
      }

      &__aside {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;

        @media (min-width: $x-large) {
          flex-direction: row;
          justify-content: space-between;
        }
        @media (min-width: $xx-large) {
          flex-direction: row;
          justify-content: space-between;
          gap: 24px;
        }

        &--doble-side {
          display: flex;
          justify-content: space-between;
          width: 100%;
          column-gap: 8px;
          @media (min-width: $x-large) {
            flex-direction: row;
            justify-content: space-between;
          }
          &-A {
            width: 100%;
            @media (min-width: $medium) {
              max-width: 160px;
            }
            @media (min-width: $x-large) {
              min-width: 130px;
              width: 100%;
            }
          }
          &-B {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-end;
            @media (min-width: $x-large) {
              min-width: 130px;
              width: 100%;
            }
          }
        }
      }

      &__article {
        width: 100%;
        max-height: 100%;
      }

      .group-elements {
        display: grid;
        grid-auto-flow: column;
        align-items: flex-end;
        justify-content: space-between;
        grid-template-columns: 40% auto;
        gap: 16px;
      }

      &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;
        margin-top: 40px;
        @media (min-width: $medium) {
          margin-top: 60px;
        }
        @media (min-width: $x-large) {
          margin-top: 60px;
          column-gap: 5rem;
        }
        &--left {
          display: flex;
          width: 100px;
        }
        &--right {
          display: flex;
          width: 100px;
        }
      }

      &__error-paragraph {
        display: flex;
        align-items: center;
        width: 100%;
        color: #660019;
        font-size: 1.2rem;
        text-align: left;
        margin-top: 0.9rem;

        &--disabled {
          display: flex;
          align-items: center;
          width: 100%;
          color: #660019;
          font-size: 1.2rem;
          text-align: left;
          margin-top: 0.9rem;
          opacity: 0.4;
        }
      }

      &__error-icon {
        margin-right: 0.7rem;
      }
    }
  }
}

.section-password-form {
  margin-bottom: 10px;
}

.modifier-accordion {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: -1rem 0;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-style: normal;
  color: white;
}

.anchor {
  display: flex;
  justify-content: left;
  color: #471e87;
  text-decoration: underline;
  cursor: pointer;
  font-size: $mobile-font;

  &.center {
    padding-top: 16px;
    justify-content: center;
  }

  &.disabled {
    display: none;
    color: #330072;
    opacity: 0.4;
    cursor: default;
  }

  @include from($x-large) {
    font-size: $sm-font;

    &.disabled {
      display: flex;
    }
  }
}

.disabled .accordion__input__text {
  color: #330072;
}

.btn-profile {
  width: 9.8rem;
  height: 3.2rem;
  font-size: 1.4rem;

  @include laptop {
    width: 11.7rem;
    height: 4rem;
    font-size: 1.68rem;
  }
}
