.containerOptionRadiogroup {
  display: flex;
  justify-content: left;
  flex-direction: row !important;
  width: auto !important;

  &.radio--error {
    input {
      border: 1px #660019 solid !important;
      background-color: #ED003914 !important;
    }
  }

  input[type=radio] {
    appearance: none;
    margin: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #6429CD;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    display: flex;
    background: #a391f0;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    cursor: pointer;

    &:disabled {
      background-color: #e2e2e3;
      border: 1px solid #c2c2c5;

      &:hover::after {
        background-color: #b8b8bc;
      }
    }

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: auto;
    }

    &:checked::after {
      background-color: #6429CD;
      box-shadow: 0px 0px 6px 0px #BDBDFF99 inset;
      box-shadow: 0px 5px 10px 0px #6429CD99;
      box-shadow: 0px 4px 4px 0px #6429CD40;
    }

    &:focus {
      outline: 1px solid #6429CD;
      box-shadow: 0px 0px 6px 0px #BDBDFF99 inset;
      box-shadow: 0px 5px 10px 0px #6429CD99;
      box-shadow: 0px 4px 4px 0px #6429CD40;
    }
  }
}
