@import 'styles/styles.scss';

%labelProps {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: 4rem;
  text-align: center;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;

  @media (min-width: 2080px) {
    border-radius: 25px;
  }
}

.upload-container {
  border-radius: 10px;
  display: block;
  font-size: 1.4rem;
  margin-top: 1rem;
  // max-width: 250px;

  .label-upload {
    &--primary {
      @extend %labelProps;
      background-color: $color-purple;
      color: #fff;
      max-width: 220px;

      @include mobile {
        padding: 0px 9px;
        max-width: 220px;
      }

      &:hover {
        box-shadow: 0px 0px 15px #6429cd,
          inset 0px 0px 10px rgba(189, 189, 255, 0.6);
        transition: 0.3s;
      }

      &--disabled {
        opacity: 40%;
        cursor: default !important;

        &:hover {
          box-shadow: unset !important;
          cursor: default !important;
        }
      }
    }

    &--secondary {
      @extend %labelProps;
      background-color: transparent;
      border: solid $color-purple 1px;
      color: $color-purple;
      padding: 1.2rem 0px;

      &:hover {
        box-shadow: 0px 0px 15px #6429cd,
          inset 0px 0px 20px rgba(189, 189, 255, 0.2);
        background: rgba(100, 41, 205, 0.3);
      }

      &--disabled {
        opacity: 40%;
        cursor: default !important;

        &:hover {
          box-shadow: unset !important;
          cursor: default !important;
          background: unset !important;
        }
      }
    }
  }

  @include desktop {
    height: 5rem;
    margin-left: 0;
    margin-right: 0;
  }
}
