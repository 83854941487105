@mixin mobile {
  @media only screen and (min-width: 320px) {
    @content;
  }
}

@mixin wideMobile {
  @media only screen and (min-width: 375px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 744px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin laptop14 {
  @media only screen and (min-width: 1366px) {
    @content;
  }
}

@mixin laptop-height {
  @media only screen and (max-height: 600px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}

@mixin auxiliaryDesktop {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}

@mixin allMobile {
  @media only screen and (min-width: 320px) and (max-width: 1280px) {
    @content;
  }
}