@import 'styles/styles.scss';

.fileUpload {
  &__loading {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__buttons {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    gap: 20rem;
    @include laptop {
      column-gap: 1.5rem;

      .label-upload {
        padding: 0 1rem;
      }
    }
  }
}

.containerLink {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 50px;
  margin-top: 1rem;
  gap: 10px;
  transition: 0.3s ease;

  &:hover {
    background-color: #6429cd4d;
    cursor: pointer;
  }

  &--disabled {
    opacity: 40%;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  &__imgLink {
    height: 16px;
    width: 16px;
  }
}

.button__send {
  width: 9.9rem;
  height: 4rem;
  margin-top: 1rem;
}

hr {
  border: 0;
  border-top: 1px solid $color-purple;
}

.fileWrapper {
  margin-bottom: 8px;
}
