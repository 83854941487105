@import '../../../styles/styles.scss';

.currentActivity {
  background: #bdbdff;
  border-radius: 25px;
  color: $color-purple;
  height: min-content;
  min-height: 100%;
  padding: 2.9rem 3.7rem 0 3.7rem;
  position: relative;
  width: 100%;

  /** Ocultar scrollbar horizontal: Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @include desktop {
    padding: 4.9rem 5.7rem 0 5.7rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 45rem;
    justify-content: center;
    text-align: center;
  }

  &__activities-lock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #bdbdff;
    box-shadow: inset 0px 4px 80px rgba(100, 41, 205, 0.25);
    border-radius: 25px;
    opacity: 60%;
    z-index: 10;
  }

  &__error {
    width: 100%;
    height: 100%;
  }

  &__loader2 {
    @extend .currentActivity;
    align-items: center;
    display: flex;
    padding: 30px;
  }

  &__title {
    color: $color-purple;
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }

  &__image {
    width: 8rem;
  }

  &__noSchedule {
    &__title {
      margin-top: 5rem;
      font-size: 1.8rem;
      max-width: 32rem;
      align-self: center;
      font-weight: 700 !important;
      line-height: 2rem;
      margin-bottom: 5px;
    }
    &__text {
      font-size: 1.4rem;
      max-width: 32rem;
      align-self: center;
      margin-bottom: 2.5rem;
      line-height: 2rem;
    }
  }

  &__details {
    &-interview {
      line-height: 2rem;
    }

    &-paragraph {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: baseline;
    margin-top: 3rem;
    padding-bottom: 2rem;
    gap: 2.3rem;

    &--send {
      display: flex;
      flex-direction: column;
      gap: 2.3rem;
      margin-top: 4rem;
      padding-bottom: 2rem;
    }

    &__completed-test {
      width: unset;
      height: 4rem;
      font-size: 1.58rem;
    }
  }
  &__information {
    margin-top: 2.4rem;
    margin-bottom: 2rem;
  }
  &__recommendations,
  &__description {
    color: $color-purple;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &__description {
    margin-top: 1.5rem;
  }

  &__interview__container {
    margin-bottom: 4rem;
  }

  &__interview {
    margin-bottom: 1.2rem;
  }

  &__buttonPrimary {
    border: none;
    background-color: #6f2fd3;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 1.6rem;
    width: 20rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: #5321a3;
    }
  }

  &__buttonPrimary--disabled {
    border: 2px solid #c5c6c8;
    background-color: #ffffff !important;
    opacity: 1;
    color: #c5c6c8;
    pointer-events: none;
    cursor: not-allowed;
  }

  &__buttonSecondary {
    border: 2px solid #6f2fd3;
    background-color: #fff;
    opacity: 1;
    color: #6f2fd3;
    cursor: pointer;
    width: 20rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }

  &__buttonSecondary--disabled {
    border: 2px solid #c5c6c8;
    background-color: #ffffff !important;
    opacity: 1;
    color: #c5c6c8;
    pointer-events: none;
    cursor: not-allowed;
  }

  &__schedule {
    padding-right: 0;
    margin-left: -1rem;
    margin-top: -1rem;
    width: 100%;
  }

  &__loader {
    align-self: center;
    margin: auto;
    padding-right: 9rem;

    @include from($high-quality) {
      padding-right: 12.2rem;
    }
  }

  &__alert {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    &-text {
      font-size: 1.6rem;
      color: $color-danger;
      margin: 0 0.7rem !important;
    }
  }

  @include from($high-quality) {
    padding-top: 9.5rem;
    padding-right: 12.3rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    &__image {
      width: 7rem;
    }

    &__recommendations,
    &__description {
      font-size: 2rem;
      margin-bottom: 1.6rem;
    }

    &__description {
      margin-top: 3.5rem;
    }

    &__buttonPrimary {
      font-size: 2rem;
      width: 25.3rem;
      height: 4.8rem;
      background: transparent;
      color: #6f2fd3;
    }

    &__schedule {
      padding-right: 0;
    }
  }
}

.errorBoundarySide__active {
  position: absolute;
  background: $background-gradient;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}