.iconNotification {
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    left: 50%;
    padding-top: 8px;
    position: sticky;
    text-align: center;
    top: 53px;
    width: 30px;
    z-index: 1;

    &.defaultIcon {
        background-color: #E7DDF8;
        position: sticky;

        .bellFill {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            position: absolute;

            path {
                fill: #6429CD;
            }
        }
    }

    &.receivedIcon {
        svg {
            background-color: #DD52DD;
            width: 30px;
            height: 30px;
            padding: 7px;
            border-radius: 50%;
            margin-top: -8px;
        }

        .bellFill {
            path {
                fill: #fff;
            }
        }
    }

    &.readIcon {
        background-color: #DD52DD;

        .bellFill {
            path {
                fill: #fff;
            }
        }
    }

}

.animation {
    animation: growAndShrink 2s ease-in-out infinite;
    border-radius: 50%;
    height: 30px;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: -1;
}

@keyframes growAndShrink {

    0%,
    100% {
        transform: scale(1);
        background-color: #DD52DD;
        border: 2px #F3D7FF solid;
    }

    50% {
        transform: scale(1.5);
        background-color: #F3D7FF;
        border: 2px #DD52DD solid;
    }
}