.AdminPanelForm {
    background-color: #f7f7f8;
    padding: 50px;
    padding-bottom: 0;

    &__Panel {
        height: 780px;
    }

    &__Header {
        margin-bottom: 19px;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        gap: 24px;
    }

    &__HeaderContainer {
        flex-grow: 1;
    }

    &__BackButton {
        width: 23px;
        border: none;
        background-color: transparent;

        >img {
            max-width: 100%;
        }
    }

    &__Email {
        text-decoration: underline;
    }

    &__Title {
        font-size: 4rem;
        font-weight: 600;
        color: #6429cd;
        margin: 0px;
        padding: 0px;
    }

    &__Description {
        font-size: 1.6rem;
        color: #6429cd;
        margin: 10px 0px;
    }

    &__ConfirmButton {
        border-radius: 50px;
        width: 140px;
        height: 40px;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        border: 1px solid transparent;
        margin-left: auto;
        cursor: pointer;
        transition: all 0.3s ease;
        background-color: hsla(267, 100%, 22%, 1);
        color: white;

        &:disabled {
            color: #828285;
            background: rgba(157, 157, 161, 0.3);
        }
    }
}

.PageWrapper {
    background-color: #f7f7f8;

    &__Panel {
        margin: 50px;

        &__DataSection {
            padding: 35px 55px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: #330072;

            &__title {
                font-size: 21px;
                font-weight: 600;
                margin-bottom: 15px;
            }

            &__element {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}