.AdminPanelForm {
  background-color: #f7f7f8;
  min-height: 100vh;
  padding-inline: 8%;
  padding-bottom: 3%;
  padding-top: 7rem;

  &__Panel {
    height: 780px;
  }

  &__Header {
    margin-bottom: 19px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap: 24px;
  }

  &__HeaderContainer {
    flex-grow: 1;
  }

  &__BackButton {
    width: 23px;
    border: none;
    background-color: transparent;

    >img {
      max-width: 100%;
    }
  }

  &__Email {
    text-decoration: underline;
  }

  &__Title {
    font-size: 4rem;
    font-weight: 600;
    color: #6429cd;
    margin: 0px;
    padding: 0px;
  }

  &__Description {
    font-size: 1.6rem;
    color: #6429cd;
    margin: 10px 0px;
  }

  &__ConfirmButton {
    border-radius: 50px;
    width: 140px;
    height: 40px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    border: 1px solid transparent;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: hsla(267, 100%, 22%, 1);
    color: white;

    &:disabled {
      color: #828285;
      background: rgba(157, 157, 161, 0.3);
    }
  }
}

.componentInfo {
  div {
    &.infoLeft {
      button {}
    }

    &.infoCenter {
      width: 70%;
      padding-left: 20px;

      h1 {}

      p {}
    }

    &.infoRight {
      button {
        &.buttonConfirm {}
      }
    }
  }
}

.containerButtonsModal {
  button {
    color: #fff;
    font-size: 16px;
    background-color: transparent;
    border: none;
    padding: 10px 5px;
    cursor: pointer;

    &.primary {
      background: #6429cd;
      border-radius: 50px;
      width: 139px;
      margin-left: 10px;

      &:hover {
        box-shadow: 0px 0px 15px #6429cd,
          inset 0px 0px 10px rgba(189, 189, 255, 0.6);
        cursor: pointer;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;

  &__purpleLine {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-between;
    background-color: #330072;

    &__shadow {
      width: 30%;
      height: 0%;
      align-self: flex-start;
      background-color: #6429cd;
      box-shadow: 0px 0px 50px 20px #6429CD;
      // opacity: 0.3;
    }
  }

  &__content {
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    background-color: #330072;

    &__logo {
      padding-left: 8%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      background-color: #f7f7f8 !important;
      border-top-right-radius: 30px;
      flex: 1;
      flex-basis: 0;
      box-sizing: border-box;
      img {
        margin-top: 20px;
        max-width: 106px;
      }
    }

    &__title {
      width: 350px;
      align-content: center;
      height: 100%;
      background-color: #f7f7f8;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 4px;
        border-radius: 7px;
        background-color: white;
        left: 0;
        transform: translateX(50%);
      }

      &::before {
        content: '';
        box-shadow: 0px 0px 18px 12px #fff3;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 20%;
      }

      &__text {
        margin: 0;
        padding: 0;
        font-size: 16px;
        height: 100%;
        width: 100%;
        color: #f7f7f8;
        background-color: #330072;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        text-align: center;
        padding-top: 13px;
      }
    }

    &__user {
      height: 100%;
      flex: 1;
      padding-right:8%;
      flex-basis: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #f7f7f8;
      border-top-left-radius: 30px;
      position: relative;

      p {
        background-color: #330072;
        text-align: center;
        align-content: center;
        color: #fff;
        cursor: pointer;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        height: 80%;
        aspect-ratio: 1/1;
        margin-top: 7px;
        border-radius: 50%;
        text-align: center;
      }

      .menu {
        list-style-type: none;
        margin: 5px 0;
        padding: 0;
        position: absolute;
        right: 20%;
        top: 95%;
        width: 130px;
        
        .menu__menuItem {
          &:hover {
            background-color: #f7f7f8;
          }

          button {
            align-items: center;
            background: none;
            background: #330072;
            border: 1px solid #330072;
            border-radius: 15px;
            border-top: 0 solid #330072;
            box-shadow: 0 12px 24px #6429cd99, inset 0 0 20px #fff9;
            color: inherit;
            color: #fff;
            cursor: pointer;
            display: flex;
            font: inherit;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            height: 35px;
            line-height: 140%;
            margin-top: 5px;
            text-align: left;
            text-align: center;
            width: 100%;

            &.visibleButton {
              display: flex;
              justify-content: center;
            }

            &.noVisibleButton {
              display: none;
            }

            &:hover {
              background: #6429cd;
              border-color: #6429cd;
              box-shadow: 0 12px 24px #6429cd99, inset 0 0 20px #fff9;
            }

            img {
              height: 15px;
              margin-left: 2%;
              margin-right: 8%;
              width: 15px;
            }
          }
        }
      }
    }

  }
}


.modalCard {
  padding: 32px 50px;
  position: relative;
  background-color: #000;
  border: 1px solid #330072;
  border-radius: 25px;
  box-shadow: #330072 0px 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &__closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    filter: invert(1);
    font-size: 20px;
  }

  &__loader {
    margin-top: 15px;
    height: 150px;
    scale: 1.7;
  }

  &__icon {
    margin: 10px auto;

  }

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  &__message {
    color: #fff;
    font-size: 16px;
    text-align: center;
  }
}