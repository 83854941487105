@import '../../../styles/styles.scss';

.a_calendar-wrap {
  position: relative;
}

.a_date {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
  position: relative;

  &__input {
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    color: #330072 !important;
    border: 1px solid #6429cd;
    padding: 0 1.5rem;
    cursor: pointer;
    background-color: rgba(100, 41, 205, 0.0784313725);

    &:focus-visible {
      outline: none;
    }

    &:hover {
      box-shadow: 0px 0px 15px $color-primary;
    }

    &::placeholder {
      color: $color-purple;
      opacity: 0.4;
    }

    &--disabled {
      cursor: default;

      &:hover {
        box-shadow: unset;
      }
    }

    &--error {
      background: rgba(237, 0, 57, 0.08);
      border: 1px solid #660019;
      box-shadow: 0px 0px 10px #ed0039;
    }
  }

  &__figure {
    position: absolute;
    right: 0;
    height: 60%;
    width: auto;
    margin-right: 0.8rem;
    transition: 0.1s ease transform;
    cursor: default;

    &--open {
      transform: rotate(180deg);
    }

    &:hover {
      cursor: pointer;
    }

    &:hover + .a_date__input {
      box-shadow: 0px 0px 15px $color-primary;
    }

    &--disabled {
      &:hover {
        cursor: default;
      }

      &:hover + .a_date__input {
        box-shadow: unset;
      }
    }
  }

  &--disabled {
    opacity: 50%;
  }
}

.calendar {
  width: auto;
  margin: auto;
  padding: 2em 1em;
  font-family: 'Poppins-Regular';
  font-size: 14px;
  background-color: #1f0d3f;
  color: #fff;
  border: 1px solid #6429cd;
  border-radius: 1.5em;
  box-shadow: inset 0px 0px 30px 0px rgba(100, 41, 205, 0.5);
  position: absolute;
  top: 4.5rem;
  z-index: 1;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin-bottom: 1.5em;
    padding: 0 2rem;
  }

  &__text {
    cursor: pointer;
    display: flex;
    column-gap: 0.8rem;

    &--decade {
      cursor: default;
    }
  }

  &__button {
    width: auto;
    height: 2rem;
    border: none;
    padding: 0;
    background-color: transparent;
    color: #fff;
    cursor: pointer;

    &--left {
      transform: rotate(90deg);
    }
    &--right {
      transform: rotate(-90deg);
    }

    &__figure {
      height: 100%;
      width: auto;
    }
  }

  &__icon {
    font-size: 2rem;
  }

  &__body {
    display: flex;
    flex-direction: column;

    &--months,
    &--years {
      max-width: 100%;
      display: grid;
      justify-content: center;
      align-items: center;
      gap: 0.8em;
    }

    &--months {
      grid-template-columns: repeat(4, 1fr);
    }

    &--years {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #fff;
    cursor: pointer;

    &--year {
      width: 80px;
      border-radius: 2em;
    }

    &:hover {
      background-color: #6429cd;
      box-shadow: inset 0px 0px 20px rgba(189, 189, 255, 0.6);
      filter: drop-shadow(0px 12px 24px rgba(100, 41, 205, 0.6));
      border: none;
    }

    &--disabled {
      display: none;
    }
  }

  &__row {
    display: flex;
    justify-content: center;
  }

  &__name-day,
  &__day-container {
    width: 40px;
    height: 40px;
    text-align: center;
    margin-bottom: 1em;
  }

  &__day-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;

    &:hover {
      background-color: #6429cd;
      box-shadow: 0px 12px 24px rgba(100, 41, 205, 0.6),
        inset 0px 0px 20px rgba(189, 189, 255, 0.6);
    }
  }

  &__name-day {
    font-weight: bolder;
  }
}

.selected {
  &__item {
    background-color: #6429cd;
    box-shadow: inset 0px 0px 20px rgba(189, 189, 255, 0.6);
    filter: drop-shadow(0px 12px 24px rgba(100, 41, 205, 0.6));
    border: none;
  }
  &--only {
    background-color: #6429cd;
    box-shadow: inset 0px 0px 20px rgba(189, 189, 255, 0.6);
    filter: drop-shadow(0px 12px 24px rgba(100, 41, 205, 0.6));
  }
}

.disabled {
  cursor: default;
  color: #9d9da199;
  pointer-events: none;
}

.range {
  border-radius: 0;
  background-color: #440099;
  box-shadow: 0px 4px 24px rgba(100, 41, 205, 0.24),
    0px 12px 24px rgba(100, 41, 205, 0.4);
}
