//* COLORS
$color-primary: #6429cd;
$color-primary-op-100: #e9e5f2;
$color-primary-op-100-rgb: rgba(100, 41, 205, 0.08);
$background-gradient: radial-gradient(ellipse, #451895 1%, #250052 80%);
$color-gradient-from-light-to-purple: linear-gradient(90deg, #ab88e7 0%, #6429cd 50%, #330072 100%);
$color-secondary: #74777b;
$color-tertiary: #f8af3c;
$color-cuaternary: #fafafa;
$color-danger: #660019;
$color-tab-calendar-gray: #666669;
$color-border-calendar-gray: #b6b6ba;
$color-white: #fff;
$color-light-purple: #bdbdff;
$color-grey: #f7f7f8;
$color-grey-black: #4d4d4f;
$color-black: #000;
$color-super-black: #000;
$color-purple: #330072;
$color-purple-secondary: #f0dcff;
$color-primary-500: rgba(100, 41, 205, 0.08);
$color-error-500: rgba(237, 0, 57, 1);
$color-error-500-op-30: rgba(237, 0, 57, 0.08);
$color-error-700: rgba(184, 0, 44, 1);
$color-black-50: lighten(
  $color: $color-black,
  $amount: 50,
);
$color-white-50: darken(
  $color: $color-white,
  $amount: 50,
);
$color-secondary-fucsia: rgba(228, 164, 255, 1);
$color-disabled: #9d9da14d;
$color-black-modals-background: #1f0d3f;
//* SHADOWS
$shadow-primary: 0px 0px 5px 0px #6429cd99;

//* FONT
$font-poppins: 'Poppins';
$mobile-font: 14px;
$sm-font: 1.5rem;
$xl-font: 1.6rem;
$hq-font: 2rem;

$mobile-title-font: 21px;
$xl-title-font: 2.6rem;
$hq-title-font: 3rem;

//* MEDIA QUERIES

//* DASHBOARD
$padding-x: 17px;
//* Navbar height
$navbar-height: 6.6rem;

$tabbar_height: 64px;
$paddingX_remember_home: 12px;
$button-atom-height: 40px;

$xl-input-height: 4.8rem;
$hq-input-height: 4.3rem;

/*Breakpoints*/
$small: 440px; /*-------- Mobile devices*/
$medium: 744px; /*-------- Tablet devices*/
$large: 960px;
$x-large: 1025px; /*-------- Desktop devices*/
$xx-large: 1250px;
$xxx-large: 1720px;
$high-quality: 2080px;
