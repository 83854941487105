@import 'styles/_variables.scss';

.Card {
  height: 100%;
  width: 100%;
  position: relative;

  &__content {
    border: 1px solid #330072;
    border-radius: 10px;
    font-family: Poppins;
    color: $color-purple;
    position: relative;
    background-color: rgba(51, 0, 114, 0.05);
    width: 100%;
    height: 100%;
  }

  &--error &__content {
    border: 1px solid $color-danger;
    color: $color-purple;
    background-color: #ED003914;
    box-shadow: 0px 0px 10px 0px rgba(237, 0, 57, 1) inset;
  }

  &__error {
    margin-top: 9px;
    color: #660019;
    display: flex;
    align-items: center;
    gap: 5px;
    height: 25px;
  }

  &__BackArrow {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 10;
  }
}
