.EmploymentSection {
  padding: 35px 70px;

  &__Title {
    font-size: 21px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: hsla(262, 67%, 48%, 1);
    margin-bottom: 22px;
  }

  &__Grid {
    --n: 2;
    /* The maximum number of columns */
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(200px, (100% - (var(--n) - 1) * 10px) / var(--n)), 1fr)
    );

    column-gap: 56px;
    row-gap: 6px;
  }
}

.CompensationSection {
  margin-top: 74px;

  &__Title {
    font-size: 21px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: hsla(262, 67%, 48%, 1);
    margin-bottom: 22px;
  }

  &__Grid {
    position: relative;
    margin-top: 30px;
    display: grid;
    grid-template-areas:
      'PayrollComponent PayrollComponent PayrollComponent'
      'Salary Salary Currency'
      'AddButtonContainer AddButtonContainer AddButtonContainer';

    column-gap: 56px;
    row-gap: 6px;
  }

  &__PayrollComponent {
    grid-area: PayrollComponent;
  }

  &__Salary {
    grid-area: Salary;
  }

  &__Currency {
    grid-area: Currency;
  }

  @media (max-width: 1010px) {
    &__Grid {
      display: block;
    }
  }

  &__AddButtonContainer {
    grid-area: AddButtonContainer;
    display: flex;
    justify-content: flex-end;
  }

  &__RemoveButtonContainer {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}
