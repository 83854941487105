@import '../../../styles/styles.scss';

.alertModal {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.2rem 2.3rem;
    background-color: #1F0D3F; 
    text-align: center;
    border-radius: 24px;
    border: 1px solid $color-primary;

    h5 {
      margin-bottom: 1.5rem;
      font-size: 3rem;
      font-weight: 400;
      color: #fff;
      text-align: center;
    }

    p {
      font-size: 1.6rem;
      color: #fff;
    }
  }

  &__icon {
    margin-bottom: 1.5rem;
    width: 8rem;
    height: 8rem;

    &--question {
      padding: 2rem;
      overflow: visible;
      border-radius: 999px;
      border: 2px solid #fff;
    }
  }

  &__buttonsContainer {
    display: flex;
    width: 100%;
    gap: 1.8rem;
  }

  &__button {
    width: auto;
    margin: 2.4rem auto 0;

    &--secondary {
      color: $color-white !important;
      border: none;
    }

    &--full {
      flex: 1;
    }
  }
}
