@import '../../../styles/styles.scss';

.change-password-form {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.6rem;
  background-color: #bdbdff;
  border-radius: 0 0 25px 25px;
  cursor: default;
  font-size: $mobile-font;
  border: 1px solid #330072;
  position: relative;
  overflow-y: hidden;
  overflow-x:hidden;

  &__description {
    display: flex;
    justify-content: center;
    margin: 1rem 1rem 3rem 1rem;
    font-size: 1.3rem;
    color: #330072;
    font-weight: 400;
    line-height: 130%;

    @include laptop{
      font-size: 1.6rem;
    }

    @include laptop-height{
      font-size: 1.5rem;
    }
  }

  &__inputs {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0rem 0rem 0rem 1rem;

    @include tablet{
      width: 24rem;
    }
    
    @include laptop{
      width: 30.3rem;
    }

    @include laptop-height{
      width: 28.3rem;
    }

  }

  &__button {
    display: flex;
    margin: 0 0 2rem 1rem;
    
    button {
      width: 11.7rem;
      height: 3.9rem;
      border-radius: 5rem;
      font-weight: 600;
      font-size: 1.5rem;

    }
  }
}


.input-password-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  &__error {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    color: #660019;
  }
}
.error-icon{
  margin-left: .2rem;
}


.input-password {
  position: relative;
  display: flex;
  border: 1px solid #440099;
  border-radius: 100px;
  background: rgba(100, 41, 205, 0.08);
  height: 4rem;
  margin: 0;

  &:focus-within {
    border: 0.1rem solid #440099;
    outline: none;
  }

  &.error {
    border-color: #660019;
    box-shadow: 0 0 10px $color-danger inset;
  }

  &.isValidCurrent{
    border-color: #440099;
    box-shadow: unset;
  }

  &.isValid {
    border-color: #00b880;
    box-shadow: 0 0 10px #00b880 inset;
  }

  &__field {
    width: 80%;
    border-radius: 1rem;
    border: none;
    padding-left: 1rem;
    background: transparent;

    &:focus {
      border: none;
      outline: none;
      background: none;
    }

    &::placeholder {
      color: #440099;
      opacity: 0.4;
    }
  }

  &__button {
    position: absolute;
    border: none;
    cursor: pointer;
    background: #440099;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: .5rem;
    right: 0;
  }
 
  &__button-error{
    position: absolute;
    border: none;
    cursor: pointer;
    background: #660019;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: .5rem;
    right: 0;
  }

}



