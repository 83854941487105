@import 'styles/_variables.scss';

.TechnicalTestLinkCard {
  border: 1px solid #330072;
  border-radius: 10px;
  background-color: rgba(51, 0, 114, 0.05);
  margin: 8px 0 8px;
  color: #330072;
  font-size: 16px;
  font-weight: bold;
  padding: 16px 0;
  display: flex;
  align-items: center;

  &__iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    margin-left: 10px;
    margin-right: 8px;
    background-color: #330072;
    border-radius: 100px;
  }
}
