@import '../../../styles/styles.scss';
* {
  box-sizing: border-box;
}
.main__comment {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #330072;

  @include desktop {
    width: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: 2.1rem;
    padding-bottom: 4.2rem;
    width: 100%;

    button-container {
      display: flex; // Cambiado a flex para alinear elementos horizontalmente
      align-items: center; // Centrar elementos verticalmente
    }

    .button-container img {
      margin-right: 2rem; // Ajusta el margen entre la imagen y el texto
      height: 1.9rem; // Ajusta la altura de la imagen (ajusta según tus necesidades)
      width: 1.88rem; // Ajusta el ancho de la imagen (ajusta según tus necesidades)
    }

    .title-text {
      display: inline-block; // Permite que los elementos se alineen horizontalmente
    }
    @include desktop {
      width: 100%;
      padding-bottom: 2.1rem;
    }
  }

  &__subtitle-comment {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 24px;

    @include tablet {
      max-width: 590px;
      margin-bottom: 25px;
    }

    @include desktop {
      width: 100%;
      margin: 0;
      line-height: 21px;
    }
  }

  &__textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;

    @include desktop {
      width: 100%;
      margin-top: 3.2rem;
    }
  }

  &__form {
    width: 100%;
  }
}

.input__textarea {
  min-height: 19.8rem;
  resize: none;
  outline: none;
  border: 1px solid #6429cd;
  border-radius: 25px;
  background: rgba(100, 41, 205, 0.08);
  color: $color-purple;
  font-size: 1.6rem;
  padding: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
  &:focus {
    color: $color-purple;
  }
  &::placeholder {
    color: rgba(51, 0, 114, 0.4);
  }
  &--error {
    background: rgba(237, 0, 57, 0.08);
    border: 1px solid #660019;
    color: #660019;
    &:focus {
      color: #660019;
    }
    &::placeholder {
      color: #660019;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @include desktop {
    min-height: 24.3rem;
  }

  &-characters {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    margin-top: 1.5rem;
    margin-left: 0.2rem;
    &--error {
      color: #660019;
    }
    @include desktop {
      margin-top: 1.1rem;
    }
  }
}

.container__button-comment {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.6rem;
  gap: 1.6rem;

  @include tablet {
    flex-direction: row-reverse;
    gap: 3rem;
    margin-top: 4.2rem;
  }

  @include laptop {
    flex-direction: row-reverse;
    gap: 3rem;
    margin-top: 4.2rem;
  }

  @include desktop {
    width: 100%;
    margin-top: 4.2rem;
  }
}
.button-comment {
  width: 18.5rem;
  height: 3.2rem;

  @include laptop {
    width: 9.9rem;
    height: 4rem;
  }
}

.button-comment__modifier {
  width: 18.5rem;
  height: 3.2rem;

  @include laptop {
    width: 12.3rem;
    height: 4rem;
  }
}
