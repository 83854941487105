@use '../../../styles/styles.scss';
@import '../../../styles/variables';
@import 'styles/styles';

.template-authentication {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  font-family: $font-poppins;
  font-weight: 400;
  font-style: normal;

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1.9rem 0 0 1.6rem;

    @include laptop{
      display: none;
    }
    
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    height: 90vh;
    border-radius: 0 0 52px 52px;

    

    &-container {
      display: flex;
      flex-flow: column nowrap;
      flex-direction: column nowrap;
      align-items: center;
      justify-content: center;
      max-width: 350px;
      position: relative;
    }
  }

  &__title-email {
    width: 38rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    color: $color-white;
    font-size: 2rem;
    margin-bottom: .5rem;

    @include laptop {
      margin-bottom: 1rem;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__subtitle {
    font-size: 1.5rem;
    width: 45rem;
  }

  &__code {
    padding: 5px;
    color: $color-white;
    margin-bottom: 1vh;
    font-size: 1.8rem;
    font-weight: 600;

    @include laptop{
      margin-top: 1rem;
    }
  }
}

.logoPragma {
  width: 50vw;
  height: 100%;
  margin-bottom: 4vh;

  @include laptop {
    width: 20rem;
  }
  @include laptop-height{
    height: 5rem;
  }
}

.icon__mail {
  width: 20vw;
  height: 100%;
  margin-bottom: 3.5vh;

  @include laptop {
    width: 15rem;
    height: 8rem;
  }

  @include laptop-height{
    height: 7rem;
  }
}

.resend__code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}


.input-code__element {
  height: 3rem;

  @include laptop-height{
    height: 3rem;
  }
  
}

.counter-down {
  text-align: center;
}

.btn-back {
  display: flex;
}