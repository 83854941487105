@import '../../../styles/styles.scss';

.modal {
  position: relative;

  &__backdrop {
    position: absolute;
    align-items: center;
    backdrop-filter: blur(15px);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    max-height: 100vh;
    max-width: 100vw;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    bottom: 0;
  }
}