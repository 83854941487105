@import '../../../styles/styles.scss';

$min_width: 3rem;
$max_width: 90%;

.tabbar {
  position: relative;
  width: 100%;
  height: 85%;

  & li {
    margin-left: 0;
  }

  &__item__active {
    cursor: pointer;
    border-top: 1px solid var(--primary-60-purple-primary-purple-500, #6429CD);
    border-bottom: 1px solid var(--primary-60-purple-primary-purple-500, #6429CD);
    background: radial-gradient(82.32% 50% at 50% 50%, #440E91 0%, #551CB0 84.5%);
    padding: 20px 20px;
    width: 100%;
    position: relative;

    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__item__inactive {
    cursor: pointer;
    padding: 20px 20px;
    position: relative;
    width: 100%;

    &:hover .tooltip-text {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  @include laptop {
    width: 100%;
  }

  &__item__inactive {
    padding: 20px 20px;
  }

  @include laptop {
    width: 100%;
  }

  @include laptop-height {
    width: 100%;
  }

  @include desktop {
    width: 100%;
    // margin-left: 3%;
  }

  &__imageUserBox {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__imageUserBox::before {
    content: '';
    display: inline-block;
    border-bottom: 2px dashed white;
    width: 35%;
    margin-bottom: 0.6rem;
  }

  &__imageUserBox::after {
    content: '';
    display: inline-block;
    border-bottom: 2px dashed white;
    width: 35%;
    margin-bottom: 0.6rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  &__logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0rem;
    width: 100%;
    left: 0;
  }

  &__imageUser {
    margin: 0 0.5rem 0 0.5rem;
    width: 1.4rem;
  }

  &__list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__logoutBox {
    flex-direction: column;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__imageLogout {
    flex-direction: column;

    &__expandIcon {
      transform: rotate(180deg);
    }
  }

  &__buttonLogout {
    border: none;
    background: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: lighter;
    outline: none;
    transition: color 0.3s ease-in-out;
  }

  &__buttonLogout:hover {
    color: white;
    opacity: 1;
    font-weight: 700;
    transition: all 0.3s ease;
  }

  @media (min-width: $medium) {
    &__list {
      display: flex;
      flex-direction: column;
    }
  }

  @include from($high-quality) {
    &__pragmaLogo {
      margin: 5.5rem 0 9rem 0;
      margin-left: calc(50% - 9.45rem);
      width: 18.9rem;
      height: 5.3rem;
    }

    &__list {
      gap: 5rem;
      margin-left: 26%;
    }
  }
}

.tooltip-text {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  color: #3F0071;
  font-size: 14px;
  font-weight: bold;
  left: 100%;
  opacity: 0;
  padding: 10px 15px;
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden;
  white-space: nowrap;

  &::before {
    content: '';
    border-color: transparent white transparent transparent;
    border-style: solid;
    border-width: 8px;
    left: -15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.tooltip-text {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  color: #3F0071;
  font-size: 14px;
  font-weight: bold;
  left: 100%;
  opacity: 0;
  padding: 10px 15px;
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden;
  white-space: nowrap;

  &::before {
    content: '';
    border-color: transparent white transparent transparent;
    border-style: solid;
    border-width: 8px;
    left: -15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.menu--transform {
  align-items: center;
  background: var(--primary-60-indigo-primary-indigo-700, #8A8AFF);
  border-radius: var(--Border-radius-border-radius-m, 8px);
  bottom: 10%;
  box-shadow: 0px 4px 24px 0px rgba(100, 41, 205, 0.24), 0px 12px 24px 0px rgba(100, 41, 205, 0.40);
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: -8%;
  width: 50px;

  img {
    height: 15px;
  }
}