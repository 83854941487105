@import '../../../styles/styles.scss';

.activityHeader {
  padding: 1px;
  height: 100%;
  margin-top: 1.5rem;

  &__button {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;

  }

  &__nav {
    position: relative;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__title {
      width: 14rem;
      font-weight: 700;
      color: #330072;
      text-align: center;
      font-size: 1.9rem;
      line-height: 100%;
    }
  }

  &__icon {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
   
    &--img{
      width: 100%;
     
    }
  }
}
