//* Margin-sides utilities
.mlr-auto {
  margin: 0 auto;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}

.mt-3 {
  margin-top: 25px;
}
.mb-3 {
  margin-bottom: 25px;
}

@for $i from 1 to 10 {
  .mt-#{$i * 5} {
    margin-top: 0.5rem * $i;
  }
  .mb-#{$i * 5} {
    margin-bottom: 0.5rem * $i;
  }
  .mr-#{$i * 5} {
    margin-right: 0.5rem * $i;
  }
  .ml-#{$i * 5} {
    margin-left: 0.5rem * $i;
  }
}

//* Text-Properties utilities
.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

//* Color utilities
.secondary-color {
  color: $color-secondary;
}

.black-color {
  color: $color-secondary;
}

.black-color-5 {
  color: rgba($color: $color-black, $alpha: 0.5);
}

//* Place Holder color

.placeholder-color {
  &::placeholder {
    color: #d2d2d6;
  }
}

//* Background Color utilities
.background-color-cuaternary {
  background-color: $color-cuaternary;
}

.bold {
  font-weight: bold;
}

//* Display-Flexbox utilities
.display-flex {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}
.gap-5 {
  gap: 0.5rem;
}
.gap-8 {
  gap: 0.8rem;
}

//* Height utilities
.min-height-100vh {
  min-height: 100vh;
}
.min-height-with-navbar {
  min-height: calc(100vh - 60.98px);
}

//* centrar contenido cuando no es tamaño desktop

.container-center-dashboard {
  @include mobile {
    width: calc(min(100%, 350px));
    margin: 0 auto;
  }

  @include desktop {
    width: 100%;
  }
}

// .backButton {
//   // @include desktop {
//   //   display: none;
//   // }
// }
