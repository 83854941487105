@import '../../../styles/styles.scss';

%buttonPrimaryProps {
  border: none;
  border-radius: 999px;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 1.2rem 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: unset;
  transition: 0.3s ease;
}

%buttonSecondaryProps {
  background-color: transparent;
  border-radius: 999px;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin buttonTertiaryProps($width: 151px) {
  border: none;
  border-radius: 999px;
  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  padding: 1.2rem 2.3rem;
  align-items: center;
  cursor: pointer;
  box-shadow: unset;
  transition: 0.3s ease;
  width: $width;
}

.button-primary {
  @extend %buttonPrimaryProps;
  background-color: #6429cd;
  color: white;

  &:hover {
    box-shadow: 0px 0px 15px #6429cd, inset 0px 0px 10px rgba(189, 189, 255, 0.6);
    cursor: pointer;
  }

  &--dark {
    @extend %buttonPrimaryProps;
    color: white;
    background-color: #330072;

    &:hover {
      box-shadow: 0px 0px 15px #6429cd, inset 0px 0px 10px rgba(189, 189, 255, 0.6);
      cursor: pointer;
    }
  }

  &--white {
    @extend %buttonPrimaryProps;
    background-color: white;
    color: #6429cd;

    &:hover {
      box-shadow: 0px 0px 15px rgba(246, 247, 252, 0.8), inset 0px 0px 20px rgba(246, 247, 252, 0.2);
      cursor: pointer;
    }
  }
}

.button-secondary {
  @extend %buttonSecondaryProps;
  border: 1px solid #6429cd;
  color: #6429cd;

  &:hover {
    box-shadow: 0px 0px 15px #6429cd, inset 0px 0px 20px rgba(189, 189, 255, 0.2);
    background: rgba(100, 41, 205, 0.3);
  }

  &--white {
    @extend %buttonSecondaryProps;
    border: 1px solid white;
    color: white;

    &:hover {
      background: rgba(157, 157, 161, 0.3);
      box-shadow: 0px 0px 15px rgba(246, 247, 252, 0.8), inset 0px 0px 20px rgba(246, 247, 252, 0.2);
    }
  }
}

.button-tertiary {
  @include buttonTertiaryProps(151px);
  background-color: #6429cd;
  color: white;
  white-space: nowrap;
  transition: opacity 0.3s ease, pointer-events 0.3s ease;

  &.button-visible {
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    box-shadow: 0px 0px 15px #6429cd, inset 0px 0px 10px rgba(189, 189, 255, 0.6);
    cursor: pointer;
  }

  &--dark {
    @include buttonTertiaryProps;
    color: white;
    background-color: #330072;

    &:hover {
      box-shadow: 0px 0px 15px #6429cd, inset 0px 0px 10px rgba(189, 189, 255, 0.6);
      cursor: pointer;
    }
  }

  &--white {
    @include buttonTertiaryProps;
    background-color: white;
    color: #6429cd;

    &:hover {
      box-shadow: 0px 0px 15px rgba(246, 247, 252, 0.8), inset 0px 0px 20px rgba(246, 247, 252, 0.2);
      cursor: pointer;
    }
  }
}

.button-third {
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  border: none;
  color: #330072;
}
.disabled-primary {
  opacity: 40%;
  cursor: default !important;

  &:hover {
    box-shadow: unset !important;
    cursor: default !important;
  }
}

.disabled-inverse {
  background: rgb(157 157 161 / 70%);
  color: white;
  cursor: default;
  pointer-events: none;
}

.disabled-secondary {
  opacity: 40%;
  cursor: default !important;

  &:hover {
    box-shadow: unset !important;
    cursor: default !important;
    background: unset !important;
  }
}

.button-cancel {
  @extend %buttonPrimaryProps;
  background: transparent;
  color: white;
}

.disabled-tertiary {
  opacity: 40%;
  cursor: default !important;

  &:hover {
    box-shadow: unset !important;
    cursor: default !important;
  }
}

.button__loader {
  width: 40px !important;
  height: 25px !important;
  overflow: hidden;
  scale: 3;
}
