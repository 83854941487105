@import 'styles/styles.scss';

.label {
  padding-top: 5px;
  color: #330072;
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 0px;
  font-weight: 700;
  margin-bottom: 0.8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  @media (max-width: 1280px) {
    display: block;
  }
  &__img {
    height: 16px;
    width: 16px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(24deg)
      brightness(103%) contrast(101%);

    &--secondary {
      filter: none;
    }
  }

  @include from($x-large) {
    font-size: $xl-font;
    margin-bottom: 0.8rem;

    &--password {
      display: none;
    }
  }

  @include from($high-quality) {
    font-size: $hq-font;
  }
}
