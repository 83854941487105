.EmergencyContactSection {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

.add-button {
  align-self: center;
}

.delete-button {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  div {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    border: 1px solid hsla(262, 67%, 48%, 1);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    path {
      fill: hsla(262, 67%, 48%, 1);
    }
  }
}
