@import '../../../styles/styles.scss';

.activityAtomTextWithAtoms {
  color: $color-purple;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
  line-height: 130%;

  &__emailHelp{
    color: #6429CD;
  }

  p{
    display: inline;
  }
  a {
    text-decoration: underline;  
  }

  @include from($x-large) {
    font-size: 1.6rem;
  }

  @include from($high-quality) {
    font-size: 1.9rem;
  }
}
