@import '../../../styles/styles.scss';

.infoModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem 3.2rem;
    background-color: $color-white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  &__headerTitle {
    font-size: 2.6rem;
    color: $color-purple;
  }

  &__headerCloseContainer {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__main {
    padding: 2.4rem 3.3rem 3.4rem;
    background-color: #f6f7fc;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  &__text {
    font-size: 1.6rem;
    color: $color-purple;
    line-height: normal;
    white-space: pre-line;
  }


  &__headerSubTitle {
    color: $color-purple;
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 2.3rem;
  }

  &__listItem {
    list-style: unset;
    margin-left: 1.1rem;
  }

  &__childrenContainer {
    margin-top: 1.9rem;
    margin-bottom: 0.7rem;
  }

  &__buttonsContainer {
    display: flex;
    gap: 2rem;
  }

  &__button {
    background-color: #6429CD;
    width: auto !important;
    margin: 2.4rem auto 0;

    &--secondary {
      border: none;
      background-color: $color-white;
    }

    &--full {
      flex: 1;
    }
  }
}