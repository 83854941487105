@import '../../../styles/styles';

.confirm-data-form {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    border-radius: 25px;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__input-spacing {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__section-title {
    grid-column: 1 / 4;
    margin: 1.8rem 0 1.2rem 0;
    margin-bottom: 2.8rem;
    font-size: 2.1rem;
    font-weight: 700;
    color: $color-purple;
  }

  &__phone-number {
    display: flex;
    align-items: baseline;
    gap: 0.7rem;
  }

  &__submit-button {
    grid-column: 1 / 4;
    display: flex;
    justify-content: center;
    margin-top: 5.5rem;
  }

  &__modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h5 {
      margin-bottom: 1.5rem;
      font-size: 3rem;
      font-weight: 400;
      color: #fff;
    }

    p {
      font-size: 1.6rem;
      color: #fff;
    }
  }

  &__modal-icon {
    margin-bottom: 1.5rem;
    width: 8rem;
    height: 8rem;

    &--question {
      padding: 2rem;
      overflow: visible;
      border-radius: 999px;
      border: 1px solid #fff;
    }
  }

  &__buttons-container {
    display: flex;
    gap: 1.6rem;
    margin-top: 3rem;

    button {
      color: white;
      border: none;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }
}
