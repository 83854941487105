@import 'styles/styles';

.backButton {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.2rem;
  }
    
}
.activityHeader__button{
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #330072;
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  padding: .5rem;
  width: 3.5rem;
}

.backSchedule{
  @extend .activityHeader__button;
  border: 1.12px solid #330072;
  height: 2.4rem;
  position: initial;
  width: 2.4rem;
}