@import '../../../styles/variables';
@import '../../../styles/styles.scss';

.tabbar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: min-content;

  @include desktop {
    gap: 2.8rem;

  }

  &__border {
    width: 0.5rem;
    border-radius: 120px;
    transform: rotate(-180deg);
    height: 3rem;

    &--active {
      background: white;
      box-shadow: 0.2rem 0 1rem #bdbdff;
      width: min-content;
    }
  }

  &__icon {

    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &--container {
      align-items: center;
      position: relative;
      display: flex;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
    }

    &--active {
      // background: radial-gradient(50% 50% at 50% 50%, #BDBDFF 0%, rgba(100, 41, 205, 0.29) 100%);
      filter: blur(9px);
      border-radius: 50%;
    }

    &--circle {
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  &--active {
    pointer-events: none;
    height: 30px;
  }

  &--inactive {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }

  &--active &__icon {
    opacity: 1;
    transition: all 0.35s ease;
    filter: brightness(100%);
  }

  &--active &__title {
    color: white;
    opacity: 1;
    transition: all 0.3s ease;
    margin-left: 1rem;
  }

  &__title {
    color: whitesmoke;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 100%;
    transition: all 0.3s ease;
    margin-left: 1rem;
  }

  @include from($x-large) {
    flex-direction: row;
    gap: 1rem;

    &__icon {
      margin-right: 1.1rem;
    }

    &__title {
      font-size: 1.6rem;
    }
  }

  @include from($high-quality) {
    &__icon {
      margin-right: 1.5rem;
      width: 2.2rem;
      height: 2.2rem;
    }

    &__title {
      font-size: 2rem;
    }
  }
}

.m-0 {
  margin: 0;
}