@import '../../../styles/styles.scss';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &_logo,
  &__image,
  &__text {
    display: none;
  }

  &__logo-background {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #250052 25%, transparent 75%);
    position: absolute;
    opacity: 0;
    left: 0;

    &--show {
      transition-property: opacity;
      transition-duration: 200ms;
      opacity: 100;
    }
  }

  &_logo {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    background: transparent;
    height: 8rem;
    margin-top: 1.5rem;
    border-radius: 0 2.5rem 0 0;
    width: 20vw;
    justify-content: center;
    opacity: 0;
    overflow: hidden;

    &--show {
      transition-property: opacity;
      transition-duration: 2s;
      opacity: 100;
    }

    @include tablet {
      width: 55vw;
      justify-content: flex-start;
      padding-left: 3vw;
    }

    @include laptop {
      padding-left: 4vw;
    }

    @include desktop {
      padding-left: 4vw;
    }

    &__image {
      width: 40px;
      height: 40px;
      margin: 13px;
      z-index: 5;
    }

    &__text {
      width: 97px;
      height: 42px;
      color: white;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      border-left: 1px solid $color-primary;
      padding-left: 10px;
      z-index: 5;
      display: flex;
      flex-direction: column;

      &--modifier {
        font-weight: 700;
      }
    }
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #6529cd;

    &--1 {
      height: 2vh;
      width: 20vw;

      @include tablet {
        width: 55vw;
      }
    }

    &--2 {
      height: 2vh;
      width: 5vw;
    }
  }

  &__container {
    height: 10rem;
    background: linear-gradient(#6529cd,
        #6529cd 3.5rem,
        transparent,
        transparent 25px);
    opacity: 0;

    &--show {
      opacity: 100;
      transition-property: opacity;
      transition-duration: 2s;
    }

    &--left {
      width: 20vw;

      @include tablet {
        width: 55vw;
      }
    }

    &--right {
      width: 5vw;
    }

    &--info {
      display: flex;
      width: 75vw;
      z-index: 2;
      opacity: 0;
      position: relative;

      &--show {
        opacity: 200;
        transition-property: opacity;
        transition-duration: 2s;
      }

      @include tablet {
        width: 40vw;
      }
    }

    &--border {
      width: 5vw;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(to bottom, #250052 25%, transparent 75%);
      z-index: 1;
      height: 8rem;
      margin-top: 1.5rem;
      border-radius: 2rem 0 0 0;
      opacity: 0;

      &--show {
        transition-property: opacity;
        transition-duration: 200ms;
        opacity: 100;
      }
    }
  }
}

.home__header {
  background: radial-gradient(ellipse, #6429cd1a, $color-primary 80%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: min-content;
  padding: 1rem 1rem;
  border-radius: 0 0 2rem 2rem;

  @include tablet {
    padding: 1.5rem 2rem;
  }

  &__img {
    width: 6rem;
    height: 6rem;
  }

  &__info {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin: 0;
    padding: 0;
    width: 341px;

    &__greetings {
      font-size: 1.9rem;
      font-weight: bolder;
      color: $color-white;

      @include tablet {
        font-size: 2.1rem;
      }
    }

    &__position {
      font-weight: 400;
      font-size: 1.2rem;
      color: $color-white;

      &__link {
        color: $color-tertiary;
        background: unset;
        border: unset;

        &.underline {
          cursor: pointer;
        }
      }
    }
  }

  .contentProfileNotifications {
    position: relative;
  }

  @include from($x-large) {
    &__img {
      width: 5rem;
      height: 5rem;
    }

    &__info__greetings {
      font-size: 2.4rem;
    }

    &__info__position {
      font-size: 1.4rem;
      position: relative;
    }
  }
}

.profile__section {
  align-items: center;
  display: flex;
  gap: 2rem;
}