@import 'styles/styles';

.schedule-part-of-day-mobile {

  display: grid;
  grid-template-rows: 10% 90%;
  height: 100%;

  &__tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &__text-container {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  &__hoursList {
    // el height deberia quedar justo donde empieza el boton de agendar (y depende del largo del celular)
    overflow-y: scroll;

    /** Ocultar scrollbar horizontal: Firefox */
    scrollbar-width: none;
    
    /** Ocultar scrollbar horizontal: Chrome */
    &::-webkit-scrollbar {
      display: none;
    }

    // TODO: recordar que el boton debe quedar fijo y el heigh debe ir hasta el boton (de acuerdo a l height del dispositivo)
  }
  
  &__noHoursList {
    // el height deberia quedar justo donde empieza el boton de agendar (y depende del largo del celular)
    overflow-y: scroll;

    /** Ocultar scrollbar horizontal: Firefox */
    scrollbar-width: none;
    
    /** Ocultar scrollbar horizontal: Chrome */
    &::-webkit-scrollbar {
      display: none;
    }

    // TODO: recordar que el boton debe quedar fijo y el heigh debe ir hasta el boton (de acuerdo a l height del dispositivo)

    display: flex;
    align-items: center;
  }
}