@import 'styles/styles';

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;

  @include desktop {
    display: block;
    text-align: left;
    margin-bottom: 24px;
  }

  &__icon {
    width: 14px;
    height: 13px;
    filter: invert(40%) sepia(5%) saturate(220%) hue-rotate(202deg)
    brightness(96%) contrast(90%); // #666669

    @include desktop {
      width: 22px;
      height: 20px;
      margin-right: 10px;

      filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(339deg) brightness(92%) contrast(101%); // #000
    }
  }
  
  &__text {
    font-weight: 500;
    font-size: 1.4rem;
    color: $color-tab-calendar-gray;
    
    @include desktop {
      font-weight: normal;
      font-size: 2.1rem;
      color: $color-super-black;
    }
  }
}
