@import '../../../styles/styles.scss';


.navigation-wrap {
  width: 100vw;
  min-height: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
}

.navigation {
  position: relative;
  width: 75vw;
  height: 100vh;
  background: $color-primary;
  box-shadow: inset 0px 0px 80px $color-primary;

  &--bg {
    width: 25vw;
    height: 100vh;
    background: #1f0d3f;
    opacity: 60%;
  }

  &-menu {
    display: flex;

    &--tabs {
      display: flex;
      margin: 4.7rem 0 0 2.8rem;
      flex-flow: column nowrap;
      row-gap: 30px;
    }

    &--tab {
      @include laptop {
        margin: 2rem 0;
      }
    }

    &__image {
      width: 4rem;
      height: 4rem;
      margin-right: 1.3rem;
    }

    &__text {
      width: 13rem;
      height: 42px;
      color: white;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      border-left: 0.1rem solid white;
      padding-left: 1rem;
      letter-spacing: 0.05rem;
      display: flex;
      flex-direction: column;

      &--modifier {
        font-weight: 700;
      }
    }
  }

  &-button {
    cursor: pointer;
    align-self: center;
    width: 2.5rem;
    z-index: 5;
  }
}