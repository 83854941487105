@import '../../../styles/styles.scss';
.stagesList {

  &--completed {
    width: 100%;
  }

  &__container {
    padding: 2rem 0;
    overflow: hidden;

    /** Ocultar scrollbar horizontal: Firefox */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    @include laptop {
      padding: 3.7rem 0;
    }

    &--whitOutStages{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--completed {
      display: flex;
      align-items: center;
      justify-content: center;

      &--withStages{
        align-items: flex-start;
      }
    }
  }

  &__loader {
    margin-top: calc(50vh - 210px);
  }
}