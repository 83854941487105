@import 'styles/styles';

.hours {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 11px;
  
  @include desktop {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 19rem));
    flex-wrap: wrap;
    gap: 20px 19px;
  }

  &__hourBtn {
    margin: auto;
    border: 1px solid $color-border-calendar-gray;
    border-radius: 8px;
    background-color: $color-white;
    height: 33px;
    color: $color-super-black;
    cursor: pointer;
    max-width: 19.2rem;
    width: 140px;
    font-size: 1.3rem;
    
    @include desktop {
      height: 48px;
      width: 192px;
      font-size: 1.8rem;
    }

    &--selected {
      color: $color-white;
      background-color: $color-primary;
      border-color: $color-primary;
      pointer-events: none;
      box-shadow: 0px 8px 16px #5F5CF880;
    }

    &:disabled {
      color: $color-border-calendar-gray;
      pointer-events: none;
    }
  }
}
