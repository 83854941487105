@use '../../../styles/styles.scss';
@import '../../../styles/variables';
@import 'styles/styles';

$char-w: 1.8ch;
$char-desk: 2.2ch;
$gap: 0.2 * $char-w;
$gap-desk: 0.2 * $char-desk;
$n-char: 6;
$in-w: $n-char * ($char-w + $gap);
$in-desk: $n-char * ($char-desk + $gap);

.template-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include laptop-height {
    width: 50%;
  }
}

.template-code__box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f6f7fc;
  border-radius: 100px;
  width: 80%;
  height: 4rem;

  &--error,
  &--error:focus {
    border: 1px solid #ed0039;
    box-shadow: 0 0 10px #ed0039 inset;
  }
  &--isValid {
    border: 1px solid #00eca5;
    box-shadow: 0 0 10px #00eca5 inset;
  }

  @include laptop-height {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  @include laptop {
    width: 60%;
  }
}

.input-code-box {
  margin-left: 8ch;
  @include laptop {
    margin-left: 4ch;
  }
}

.input-code__element {
  padding-left: 0.4ch;
  color: $color-white;
  display: block;
  width: 16.5ch;
  border: none;
  background: repeating-linear-gradient(
      90deg,
      $color-white 0,
      $color-white $char-w,
      transparent 0,
      transparent $char-w + $gap
    )
    0 100% / #{$in-w - $gap} 1.7px no-repeat;
  font: 4.4ch droid sans mono, consolas, monospace;
  letter-spacing: 1.15ch;

  &--error {
    background: repeating-linear-gradient(
        90deg,
        $color-white,
        $color-white $char-w,
        transparent 0,
        transparent $char-w + $gap
      )
      0 100% / #{$in-w - $gap} 1.7px no-repeat;
    font: 4.4ch droid sans mono, consolas, monospace;
    letter-spacing: 1.15ch;
  }
  &--isValid {
    background: repeating-linear-gradient(
        90deg,
        $color-white,
        $color-white $char-w,
        transparent 0,
        transparent $char-w + $gap
      )
      0 100% / #{$in-w - $gap} 1.7px no-repeat;
    font: 4.4ch droid sans mono, consolas, monospace;
    letter-spacing: 1.15ch;
  }

  &:focus {
    outline: none;
  }

  @include laptop {
    padding-left: 0.7ch;
    letter-spacing: 1.6ch;
    background: repeating-linear-gradient(
        90deg,
        $color-white,
        $color-white $char-desk,
        transparent 0,
        transparent $char-desk + $gap-desk
      )
      0 100% / #{$in-desk - $gap-desk} 1.7px no-repeat;
    font: 4ch droid sans mono, consolas, monospace;

    &--error,
    &--isValid {
      letter-spacing: 1.6ch;
      background: repeating-linear-gradient(
          90deg,
          $color-white,
          $color-white $char-desk,
          transparent 0,
          transparent $char-desk + $gap-desk
        )
        0 100% / #{$in-desk - $gap-desk} 1.7px no-repeat;
      font: 4ch droid sans mono, consolas, monospace;
    }
  }
}

.btn__code {
  width: 20rem;
  height: 4rem;

  @include laptop {
    width: 22.7rem;
    height: 4rem;
  }
}

.error-code {
  width: 90%;
  color: #ffb8c9;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: 0.3rem;
  margin-left: 1rem;

  @include tablet {
    margin-left: 9rem;
  }

  @include laptop {
    margin-left: 13rem;
    font-size: 1.5rem;
  }
}

.error-icon {
  width: 1.5rem;
  margin-left: 1rem;
  margin-top: 0.5rem;

  @include laptop {
    width: 1.8rem;
    margin-right: 0.5rem;
  }

  @include laptop-height {
    margin-top: 1rem;
  }
}

.template-confirmation {
  margin-top: 2rem;

  @include laptop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.2rem;
    margin-top: 3rem;
  }

  &__resend__email {
    @include laptop {
      order: 1;
    }
  }
}
