@import '../../../styles/styles.scss';

.alert-toast{

    &__wrapper{
        width: 100vw;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;

        &--profile{
            width: 100%;
        }
    }

    &__toast{
        width: 29.4rem;
        height: 7.6rem;
        background-color: #00b880;
        border-radius: 2.5rem;
        box-shadow: 0px 12px 24px rgba(0, 236, 165, 0.4), inset 0px 0px 20px rgba(0, 236, 165, 0.6);
        display: flex;
        align-items: center;
        padding: 2.3rem;
        position: absolute;
        bottom: -100px;
        transition: 1s;
        z-index: 100;

        &--profile{
            width: 23rem !important;

            @include tablet{
                width: 29.4rem !important;
            }
        }

        &--show{
            transition: 1s;
            width: 29.4rem;
            height: 7.6rem;
            background-color: #00B880;
            border-radius: 2.5rem;
            box-shadow: 0px 12px 24px rgba(0, 236, 165, 0.4), inset 0px 0px 20px rgba(0, 236, 165, 0.6);
            display: flex;
            align-items: center;
            padding: 2.3rem;
            position: absolute;
            bottom: 70px;
            z-index: 100;
        }

        &--show-profile{
            transition: 1s;
            width: 29.4rem;
            height: 7.6rem;
            background-color: #00B880;
            border-radius: 2.5rem;
            box-shadow: 0px 12px 24px rgba(0, 236, 165, 0.4), inset 0px 0px 20px rgba(0, 236, 165, 0.6);
            display: flex;
            align-items: center;
            padding: 2.3rem;
            position: absolute;
            bottom: 20px;
            z-index: 100;
        }

        @include laptop-height{
            &--show{
            bottom: 10px;
        }
        }
    }

    &__icon{
        margin-right: 1.2rem;
    }

    &__text{
        font-size: 1.6rem;
        font-weight: 700;
        color: white;

        &--profile{
            font-size: 1.1rem;

            @include tablet{
                font-size: 1.6rem;
            }
        }
    }
}