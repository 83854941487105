@import '../../../styles/styles.scss';

.activities {
  display: grid;
  grid-template-rows: 50% 50%;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__description {
   
    &::-webkit-scrollbar {
      display: none;
    }
    
    overflow-y: scroll;
    scrollbar-width: none;
    height: 100%;
  }

  &__buttons {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 27rem;
  }
}


.button__mobile{
  margin-top: 1.5rem;
  font-size: 1.3rem;
  width: 18.5rem;
  height: 3.2rem;
  padding: 1.2rem;

  &--attended {
    width: 19.5rem;
  }
}
