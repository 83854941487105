@import '../../../styles/styles.scss';

.green{
    filter: invert(78%) sepia(34%) saturate(6353%) hue-rotate(114deg) brightness(103%) contrast(103%);
}

.icon{ 
    height: 32px;
    position: absolute;
    right: 18px;
    top: 18px;
}

.stage-card-large{
    align-items: center;
    border-radius: 11px;
    border: 2px solid #8169ca;
    box-shadow: 0px 0px 30px 0px #6429CDB2 inset;
    box-shadow: 0px 4px 20px 0px #6429CDB2;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px 30px;
    position:relative;
    width: 100%;

    img{
        height: 3.125rem;
        width: 3.125rem;
    }

    @media (max-width: $medium) {
      flex-direction: column;
    }

    &__icon{
        width: 70px;
    }

    &__icon img{
        width: 70px;
    }

    &__disabled{
        @extend .stage-card-large;
        opacity: 50%;        
    }

    &__info{
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 6px;

        &-title{
            font-size: $hq-font;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        &-text{ 
            line-height: 1.3;       
            font-size: $sm-font;
        }

        @include mobile{
            width: 90%;
        }
    }
    &__button{
        align-self: end;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 86px;
        button{
            white-space: nowrap;
            font-size: 14px;
        }
        @include mobile{
            margin: 0 auto;
        }
    }
}

.button-primary{
    width: 100%;
}