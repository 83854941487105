@import '../../../styles/styles.scss';

.stagesHome {
  position: relative;
  background: rgba(100, 41, 205, 0.05);
  border: .1rem solid $color-primary;
  box-shadow: inset 0px .4rem 8rem rgba(100, 41, 205, 0.25);
  border-radius: 2.5rem;
  width: 90vw;
  min-height: 676px;
  padding: 0 1.6rem;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: 15% 85%;

  @include laptop {
    width: 100%;
    grid-template-rows: 10% 90%;
  }

  &__tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &--active {
      z-index: 100;
    }
  }

  &__overflow {
    overflow-y: visible;
  }
}
