@import '../../../styles/styles.scss';

.home-remember {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(100, 41, 205, 0.05);
  border: .1rem solid $color-primary;
  box-shadow: inset 0px .4rem 8rem rgba(100, 41, 205, 0.25);
  border-radius: 2.5rem;
  width: 90vw;
  padding: 2.2rem 1.7rem;
  height: 124px;

  @include laptop{
    width: 100%;
  }

  &__info {
    &__title {
      color: white;
      font-weight: 600;
      font-size: 2.2rem;

      @include laptop {
         font-size: 2.6rem;
      }
    }

    &__text {
      color: white;
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
  &--active {
    z-index: 10000;
  }
}