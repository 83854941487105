@import '../../../styles/styles.scss';

.atomTable {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-size: small;
  font-family: $font-poppins;
  &__tableThreeCols {
    position: relative;
    color: $color-purple;
    tr {
      th {
        width: 33%;
        padding: 1.2rem 1.2rem 2.1rem 1.2rem;
      }
    }
  }
  &__tableFiveCols {
    position: relative;
    color: $color-purple;
    tr {
      th {
        width: 20%;
        padding: 1.2rem 1.2rem 2.1rem 1.2rem;
      }
    }
  }

  &__tableBody {
    &__tableRow {
      border-bottom: 1px solid $color-primary;
    }
  }
  th,
  td {
    padding: 1rem;
    text-align: left;
    position: relative;
    color: $color-purple;
  }
}

.actionbutton {
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 50%;
  right: 1rem;
  transform: translate(-50%, -50%);
  img {
    filter: invert(1);
    width: 1rem;
    height: 1rem;
  }
}
.tableContainer {
  display: flex;
  flex-direction: column;
}

.paginationRow {
  width: 10px;
  height: 10px;
}

.scrollTable {
  max-height: 39.4rem;
  overflow: auto;
}

.navContainer {
  padding-left: 95%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;

  ul {
    list-style: none;
    display: flex;
    gap: 0.5rem;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    color: $color-purple;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color-primary;
      font-weight: bold;
      color: white;
    }

    &.active {
      background-color: $color-primary;
      box-shadow: 0px 5px 10px $color-primary;
      font-weight: bold;
      color: white;
    }
  }
}
