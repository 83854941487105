.SummarySection {
  display: flex;
  flex-direction: column;
  gap: 16px;

  ul {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2rem 1rem;
  }
}

.RadioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid hsla(262, 67%, 48%, 1);
  width: 24px;
  height: 24px;
  background: hsla(262, 67%, 48%, 0.3);
  border-radius: 100%;
  cursor: pointer;

  input {
    display: none;
  }

  &__inner {
    width: 12px;
    height: 12px;
    background: hsla(262, 67%, 48%, 1);
    border-radius: 100%;
    display: none;
    box-shadow: 0px 0px 6px 0px hsla(240, 100%, 87%, 0.6) inset;
    filter: drop-shadow(0px 4px 4px hsla(262, 67%, 48%, 0.25))
      drop-shadow(0px 5px 10px hsla(262, 67%, 48%, 0.6));
  }

  &--selected &__inner {
    display: block;
  }
}

.DeclaracionJurada {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: hsla(267, 100%, 22%, 1);

  display: flex;
  justify-content: center;
  gap: 10px;
}
