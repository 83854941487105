@import '../../../styles/styles.scss';

.interviewInfo{
  &__textIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2vh;
  }
  
  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0;
    margin-right: 2.6rem;
  }

  &__paragraph{
    margin-left: 1rem;
    color:white;
  }
  
  // &__spacing {
  //   width: 75%;
  //   margin: auto;

  //   @include desktop{
  //     width: unset;
  //     margin: unset;
  //   }
  // }
}