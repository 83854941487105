@import 'styles/styles';

.card-schedule-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  min-width: 45px;
  height: 71px;
  background-color: $color-white;
  
  border: 1px solid $color-border-calendar-gray;
  border-radius: 8px;
  
  @include desktop {
    width: 7.2rem;
    height: 9.6rem;
  }

  
  &--selected {
    background-color: $color-primary;
    box-shadow: 0px 8px 16px #5F5CF880;
    border-color: $color-primary;
    pointer-events: none;
  }

  &__today {
    font-weight: bolder;
    font-size: 1.2rem;
    color: $color-super-black;
    
    // @include desktop {
    //   font-size: 2.1rem;
    // }
  }
  
  &--selected &__today {
    color: $color-white;
  }
  
  &__text {
    font-size: 1.6rem;
    color: $color-super-black;

    @include desktop {
      font-size: 2.4rem;
    }
  }

  &--selected &__text  {
    color: $color-white;
  }
}