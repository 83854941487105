@import 'styles/styles.scss';

.tooltip__container {
  position: relative;
  display: flex;
  width: fit-content;
  cursor: pointer;

  img {
    max-width: 20px;
  }

  .tooltip__text {
    --scale: 0;
    position: absolute;
    left: 50%;
    transform: translateY(calc(-100% - 1.2rem)) translateX(-50%) scale(var(--scale));
    transition: 0.2s transform;
    transform-origin: bottom center;
    border-radius: 0.6rem;
    width: max-content;
    height: fit-content;
    max-width: 25rem;
    padding: 1.4rem;
    text-align: left;
    color: $color-black-50;
    font-size: 1.2rem;
    box-shadow: 0px 12px 24px 0px #ffffff66;
    line-height: 1.6rem;
    z-index: 102;

    &--white,
    &--purple {
      &::after {
        content: '';
        position: absolute;
        transform-origin: top center;
        border: 1rem solid transparent;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
      }
    }

    &--white {
      background-color: $color-white;

      &::after {
        border-top-color: $color-white;
      }
    }

    &--purple {
      background-color: $color-primary;
      box-shadow: 0px 12px 24px rgba(100, 41, 205, 0.4) inset, 0px 4px 24px rgba(100, 41, 205, 0.24);
      color: $color-white;

      &::after {
        border-top-color: $color-primary;
      }
    }

    strong {
      color: #1d1d1b;
    }
  }

  &:hover .tooltip__text {
    --scale: 1;
  }
}

