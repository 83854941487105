.PersonalDataSection {
  padding: 35px 70px;

  &__Title {
    font-size: 21px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: hsla(262, 67%, 48%, 1);
    margin-bottom: 22px;
  }

  &__Grid {
    --n: 3;
    /* The maximum number of columns */
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(200px, (100% - (var(--n) - 1) * 10px) / var(--n)), 1fr)
    );

    column-gap: 32px;
    row-gap: 12px;
  }
}
