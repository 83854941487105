@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,800;1,900&display=swap');

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 900;
  font-style: normal;
  src: url('./fonts/SofiaPro/Black.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 900;
  font-style: italic;
  src: url('./fonts/SofiaPro/Black_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 800;
  font-style: normal;
  src: url('./fonts/SofiaPro/Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 800;
  font-style: italic;
  src: url('./fonts/SofiaPro/Bold_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 700;
  font-style: normal;
  src: url('./fonts/SofiaPro/SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 700;
  font-style: italic;
  src: url('./fonts/SofiaPro/SemiBold_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/SofiaPro/Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 500;
  font-style: italic;
  src: url('./fonts/SofiaPro/Medium_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/SofiaPro/Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/SofiaPro/Regular_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 300;
  font-style: normal;
  src: url('./fonts/SofiaPro/Light.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 300;
  font-style: italic;
  src: url('./fonts/SofiaPro/Light_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 200;
  font-style: normal;
  src: url('./fonts/SofiaPro/ExtraLight.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 200;
  font-style: italic;
  src: url('./fonts/SofiaPro/ExtraLight_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 100;
  font-style: normal;
  src: url('./fonts/SofiaPro/UltraLight.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-weight: 100;
  font-style: italic;
  src: url('./fonts/SofiaPro/UltraLight_Italic.otf') format('opentype');
}

body {
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 400;
  line-height: 2rem;
}

h1,
h2,
h3,
h4,
p {
  font-weight: 400;
}
