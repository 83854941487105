@import '../../../styles/styles.scss';
.activityAtomImage {
  border-radius: 0.6rem;
  display: block;
  margin: 1rem auto;
}

.purple{
  filter: invert(9%) sepia(32%) saturate(6846%) hue-rotate(260deg) brightness(115%) contrast(123%);
}
