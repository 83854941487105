@import '../../../styles/styles.scss';

.containerPicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.centerButton {
  margin: 0 auto;
}

.modalAlert {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  row-gap: 20px;
}

.modalContainer {
  align-items: flex-start;
  display: flex;
  gap: 22px;

  &__text {
    flex-direction: column;
  }
}

.shiftDay {
  align-items: baseline;
  display: flex;
  gap: 5px;

  &__schedule {
    @extend .shiftDay;
    align-items: initial;
  }
}

.whiteIcon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(24deg) brightness(103%) contrast(101%);
  max-width: 18.72px;
}

.normalText {
  color: $color-purple;
  font-size: 16px;
}

.whiteText {
  color: #fff !important;
}

.currentActivity {
  background: #bdbdff;
  border-radius: 25px;
  color: $color-purple;
  height: min-content;
  min-height: 100%;
  padding: 2.9rem 3.7rem 0 3.7rem;
  position: relative;
  width: 100%;

  &--show {
    z-index: 1000;
  }

  /** Ocultar scrollbar horizontal: Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

  @include desktop {
    padding: 4.9rem 5.7rem 0 5.7rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 45rem;
    justify-content: center;
    text-align: center;
  }

  &__activities-lock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #bdbdff;
    box-shadow: inset 0px 4px 80px rgba(100, 41, 205, 0.25);
    border-radius: 25px;
    opacity: 60%;
    z-index: 10;
  }

  &__error {
    width: 100%;
    height: 100%;
  }

  &__loader2 {
    @extend .currentActivity;
    align-items: center;
    display: flex;
    padding: 30px;
  }

  &__title {
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }

  &__image {
    width: 8rem;
  }

  &__details {
    &-interview {
      line-height: 2rem;
    }

    &-paragraph {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    align-items: baseline;
    margin-top: 3rem;
    padding-bottom: 2rem;
    gap: 2.3rem;

    &--send {
      display: flex;
      flex-direction: column;
      gap: 2.3rem;
      margin-top: 4rem;
      padding-bottom: 2rem;
    }

    &__completed-test {
      width: unset;
      height: 4rem;
      font-size: 1.58rem;
    }
  }

  &__information {
    margin-top: 2.4rem;
    margin-bottom: 2rem;
  }

  &__recommendations,
  &__description {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &__description {
    margin-top: 1.5rem;
  }

  &__interview__container {
    margin-bottom: 4rem;
  }

  &__interview {
    margin-bottom: 1.2rem;
  }

  &__buttonPrimary {
    border: none;
    background-color: #6f2fd3;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 1.6rem;
    width: 20rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: #5321a3;
    }
  }

  &__buttonPrimary--disabled {
    border: 2px solid #c5c6c8;
    background-color: #ffffff !important;
    opacity: 1;
    color: #c5c6c8;
    pointer-events: none;
    cursor: not-allowed;
  }

  &__buttonSecondary {
    border: 2px solid #6f2fd3;
    background-color: #fff;
    opacity: 1;
    color: #6f2fd3;
    cursor: pointer;
    width: 20rem;
    height: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }

  &__buttonSecondary--disabled {
    border: 2px solid #c5c6c8;
    background-color: #ffffff !important;
    opacity: 1;
    color: #c5c6c8;
    pointer-events: none;
    cursor: not-allowed;
  }

  &__schedule {
    padding-right: 0;
    margin-left: -1rem;
    margin-top: -1rem;
    width: 100%;
  }

  &__loader {
    align-self: center;
    margin: auto;
    padding-right: 9rem;

    @include from($high-quality) {
      padding-right: 12.2rem;
    }
  }

  &__alert {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    &-text {
      font-size: 1.6rem;
      color: $color-danger;
      margin: 0 0.7rem !important;
    }
  }

  @include from($high-quality) {
    padding-top: 9.5rem;
    padding-right: 12.3rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    &__image {
      width: 7rem;
    }

    &__recommendations,
    &__description {
      font-size: 2rem;
      margin-bottom: 1.6rem;
    }

    &__description {
      margin-top: 3.5rem;
    }

    &__buttonPrimary {
      font-size: 2rem;
      width: 25.3rem;
      height: 4.8rem;
      background: transparent;
      color: #6f2fd3;
    }

    &__schedule {
      padding-right: 0;
    }
  }
}

.wrapper__fileUpload {
  width: 100%;
}

.confirmationMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 20px;

  img {
    width: 20px;
    height: 20px;
  }

  p {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.confirmationMessage--active {
  display: none;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  margin-bottom: 15px;
  margin-left: 8px;
  color: $color-danger;
  font-size: 10px;
}

.p-message {
  align-items: center;
  color: $color-danger;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  justify-content: flex-start;
  margin-bottom: 23px;
  margin-left: 8px;
  opacity: 1;
  width: 100%;
}

.btn__desktop-edit {
  padding: 16px 24px;
  height: 4rem;
  font-size: 1.6rem;
}

.testWithFiles__button {
  height: 4rem;
  width: 17rem;
}

.pt-1 {
  padding-top: 4rem;
}

.tooltip-container {
  position: relative;
  display: inline-block;

  &:hover .tooltip__schedule {
    opacity: 1;
    visibility: visible;
  }
}

.tooltip__schedule {
  background-color: white;
  border-radius: 8px;
  bottom: 120%;
  box-shadow: 0px 12px 24px 0px rgba(255, 255, 255, 0.4);
  color: #3F0071;
  font-size: 14px;
  font-weight: lighter;
  opacity: 0;
  padding: 10px 15px;
  position: absolute;
  transition: all 0.3s ease;
  text-align: center;
  visibility: hidden;
  z-index: 10;
  white-space: normal;

  &::before {
    content: "";
    border-color: white transparent transparent transparent;
    border-style: solid;
    border-width: 8px;
    position: absolute;
    top: 112%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.button__test{
  width: 148px;
  height: 40px;
  background-color: #330072;
  color: white;
  border: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button__test:hover{
  box-shadow: 0px 0px 15px #6f2fd3,
        inset 0px 0px 0px rgba(189, 189, 255, 0.6);
}