@import '../../../styles/styles.scss';

.login-page {
  display: flex;
  flex-direction: column;
  background: $background-gradient;
  height: 100vh;
  min-width: 320px;
  min-height: 667px;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;
  }

  &__animation{
    animation: hidden 1s 2.5s;
    
    @include tablet{
      width: 28rem;
    }
    
    @include desktop {
      width: 36rem;
    }

    &--hidden{
      display: none;
    }
  }

  @keyframes hidden {
    0%{
      opacity: 100%;
    }
    100%{
      opacity: 0%;
    }
  }

  &__logo-pragma{
    display: none;
    
    &--show{
      display: unset;
      padding-top: 10.5px;
      animation: showLogo 1s .3s forwards;
      opacity: 0%;
      width: 22rem;

      @include desktop{
        width: 27rem;
      }

      & img{
        width: 100%;
      }
    }
  }

  @keyframes showLogo {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
  
  &__authenticate {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  &__info-pragma {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include desktop{
      width: 50%;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: 3.2rem;
    animation: show 1s 3.5s forwards;
    opacity: 0;

    &-text{
      font-size: 2.1rem;
      color: $color-white;
      font-weight: 600;

      @include desktop{
        font-size: 2.6rem;
      }

      &--p {
        color: $color-tertiary;
        margin-top: 0.5rem;
      }
    }
  }

  &__form{
    animation: show 1s 3.5s forwards;
    opacity: 0;
    width: 26rem;

    @include tablet{
      width: 35rem;
    }

    @include desktop{
      width: 50rem;
    }
  }

  @keyframes show {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
 
}

.googleText{
padding: unset;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 100%;
text-align: center;
margin-left: 10px;
color: #6429CD;


}
