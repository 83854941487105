@import '../../../styles/styles.scss';

.infoVacant {
  &__back {
    position: absolute;
    top: 0;
    left: 20%;
    width: 80%;
    height: 100vh;
    z-index: 20;
    display: flex;
    backdrop-filter: blur(40px);
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-in;

    &--show {
      transition: 0.5s;
      visibility: visible;
      opacity: 100;
    }

    &--full {
      left: 0%;
      width: 100%;

      @include laptop {
        left: 5%;
        width: 95%;
      }

      @include desktop {
        left: 3%;
        width: 97%;
      }
    }

    &--fixed {
      left: 0%;
      width: 100%;
    }
  }


  &__container {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 2.5rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2.9rem 0 4.1rem;
      box-sizing: border-box;
      width: 100%;
      height: 10%;
    }

    &-info {
      background-color: #f6f7fc;
      box-sizing: border-box;
      padding: 3.4rem 6rem 0 4.1rem;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: 0.8rem;
      scrollbar-color: $color-purple #f6f7fc;

      &::-webkit-scrollbar {
        width: 0.8rem;
        background-color: #f6f7fc;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-purple;
        border-radius: 0.5rem;
      }

      h2 {
        color: #330072 !important;
        margin-bottom: 1rem !important;
      }

      p {
        color: $color-purple !important;
        font-size: 1.6rem !important;
        margin-bottom: 1rem !important;
        line-height: 2.5rem;

        &:first-of-type {
          margin-bottom: 1.8rem !important;
        }
      }

      strong {
        color: $color-purple !important;
        font-size: 1.6rem !important;
        font-weight: 700 !important;
        margin: 2rem 0 1rem 0 !important;
      }

      div {
        margin-bottom: 1rem !important;

        @include tablet {
          margin-bottom: 2rem !important;
        }
      }

      ul {
        list-style-type: disc !important;
        margin-left: 2.5rem !important;
        margin-bottom: 1rem !important;
        line-height: 2.4rem !important;

        li {
          list-style: unset !important;
          color: $color-purple !important;
          font-size: 1.6rem !important;

          span {
            font-size: 1.6rem !important;
            font-weight: normal !important;
          }
        }
      }

      span {
        color: $color-purple !important;
        font-size: 1.6rem !important;
        font-weight: 700 !important;
        margin: 2rem 0 1rem 0 !important;
      }
    }


  }

  &__wrap {
    width: 100%;
    height: 90%;
    background-color: #f6f7fc;
    box-sizing: border-box;
    padding-bottom: 3.4rem;
  }

  &__title {
    color: $color-purple;
    font-size: 1.9rem;
    font-weight: 700;

    @include tablet {
      font-size: 2.6rem;
    }
  }

  &__close-button {
    border: unset;
    background: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: brightness(65%);
  }
}