@import '../../../styles/styles.scss';

.mobileNoCurrentActivity {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 95vw;
  text-align: center;
  background: rgba(10, 9, 12, 0.6);
  border: 1px solid #6429cd;
  border-radius: 16px;
  margin: 2.5vh 2.5vw;
  padding: 0 0.5rem;
  z-index: 5;

  &--bg {
    background: $background-gradient;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    top: 0;
    left: 0;
  }

  &__title {
    font-size: 2.1rem;
    font-weight: 500;
    margin-bottom: 2.6rem;
    letter-spacing: 1px;
    line-height: 140%;
    text-align: center;
    color: white;
  }

  &__image {
    width: 8.6rem;
    height: 8.6rem;
    margin-bottom: 2.6rem;
  }

  &__text {
    width: 90%;
    font-size: 1.4rem;
    color: white;
    line-height: 130%;
    margin-bottom: $padding-x;
  }

  &__button {
    margin-top: 2.4rem;
    cursor: pointer;
  }

  &__study {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    margin-top: 2rem;
  }

  &__wrapperStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4.7rem;
  }

  &__imageStudy {
    width: 100%;
    margin: auto;
    margin-bottom: 0.7rem;
  }

  &__link {
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #bdbdff;
    text-decoration: underline;
  }
}
