@use '../../../styles/styles.scss';
@import '../../../styles/variables';
@import 'styles/styles';

.resend-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  @include tablet{
    margin-bottom: 2rem;
  }
  @include laptop{
    margin-bottom: 0;
  }
}

.button {
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  height: 40px;
  color: $color-white;
  cursor: pointer;
  padding: 2.3vh 0;
  background-color: transparent;
  border:  1px solid #F6F7FC;
  border-radius: 50px;  

  @include laptop{
    width: 22.7rem;
   
  }
}

.button-dis {
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgba(157, 157, 161, 0.6);
  font-size: 1.6rem;
  height: 40px;
  padding: 2.3vh 0;
  cursor:not-allowed;
  pointer-events: none;
  background-color: transparent;
  opacity: 68;
  border: 1px solid rgba(157, 157, 161, 0.6);
  border-radius: 50px;

  @include laptop{
    width: 22.7rem;
    padding: 2rem 0;
  }
}

